/**
 * Types of mobile devices.
 */
export enum MobileDevice {
    android = 'android',
    iphone = 'iphone',
    other = 'other',
}

/**
 * @description Function for determine mobile OS.
 * @returns {MobileDevice | null} Type of mobile OS
 * or "null" if current device is not mobile.
 */
export const getMobileDevice = (): MobileDevice | null => {
    if (/Android/i.test(navigator.userAgent)) {
        return MobileDevice.android;
    }

    if (/iPhone/i.test(navigator.userAgent)) {
        return MobileDevice.iphone;
    }

    return MobileDevice.other;
};
