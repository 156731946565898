/* tslint:disable */
/* eslint-disable */

/**
* @description ServiceError
*/
export class ServiceError {
    public code: string;
    public message?: string;

    constructor(code: string, message?: string) {
        this.code = code;
        this.message = message;
    }
}
/**
* @description Services.
*/
export const Services = {
	accountPay: 'accountPay',
	actions: 'actions',
	audit: 'audit',
	authorization: 'authorization',
	autocar: 'autocar',
	billboard: 'billboard',
	callback: 'callback',
	card: 'card',
	cardIssue: 'cardIssue',
	cardPay: 'cardPay',
	carloan: 'carloan',
	charge: 'charge',
	chargepay: 'chargepay',
	chronology: 'chronology',
	claims: 'claims',
	clientCabinet: 'clientCabinet',
	closingAccount: 'closingAccount',
	communication: 'communication',
	concierge: 'concierge',
	courierDelivery: 'courierDelivery',
	creditcard: 'creditcard',
	creditpay: 'creditpay',
	currentAccount: 'currentAccount',
	customerinfo: 'customerinfo',
	deeplink: 'deeplink',
	demandpay: 'demandpay',
	dialogs: 'dialogs',
	fileUpload: 'fileUpload',
	finance: 'finance',
	instantpay: 'instantpay',
	legalpay: 'legalpay',
	limit: 'limit',
	localization: 'localization',
	loyalty: 'loyalty',
	mortgage: 'mortgage',
	notification: 'notification',
	operationTemplate: 'operationTemplate',
	paperlessOffice: 'paperlessOffice',
	productCatalog: 'productCatalog',
	push: 'push',
	qrrecognition: 'qrrecognition',
	registration: 'registration',
	repayment: 'repayment',
	requisitePay: 'requisitePay',
	sale: 'sale',
	saving: 'saving',
	servicePay: 'servicePay',
	servicePoint: 'servicePoint',
	statement: 'statement',
	tariff: 'tariff',
	vendor: 'vendor',
};

/**
* @description AccountPayMethods.
*/
export enum AccountPayMethods {
	'accountAccountNumberGet' = 'accountAccountNumberGet',
	'accountCardNumberGet' = 'accountCardNumberGet',
	'accountPaymentGet' = 'accountPaymentGet',
	'accountPhoneGet' = 'accountPhoneGet',
	'clientAccountPost' = 'clientAccountPost',
	'clientAccountByTemplatePost' = 'clientAccountByTemplatePost',
	'clientAccountGet' = 'clientAccountGet',
	'clientAccountPut' = 'clientAccountPut',
	'clientAccountPatch' = 'clientAccountPatch',
	'clientAccountExecutedPatch' = 'clientAccountExecutedPatch',
	'clientPartialUpdatePatch' = 'clientPartialUpdatePatch',
	'depositPost' = 'depositPost',
	'depositMinimumContributionGet' = 'depositMinimumContributionGet',
	'depositPut' = 'depositPut',
	'depositExecutedPatch' = 'depositExecutedPatch',
	'exchangePost' = 'exchangePost',
	'exchangeMainCurrencyGet' = 'exchangeMainCurrencyGet',
	'exchangeGet' = 'exchangeGet',
	'exchangePut' = 'exchangePut',
	'exchangePatch' = 'exchangePatch',
	'exchangeExecutedPatch' = 'exchangeExecutedPatch',
	'ownPost' = 'ownPost',
	'ownByTemplatePost' = 'ownByTemplatePost',
	'ownPartialUpdatePatch' = 'ownPartialUpdatePatch',
	'ownGet' = 'ownGet',
	'ownPut' = 'ownPut',
	'ownPatch' = 'ownPatch',
	'ownExecutedPatch' = 'ownExecutedPatch',
	'exchangedealGet' = 'exchangedealGet',
	'exchangedealPut' = 'exchangedealPut',
	'exchangedealPost' = 'exchangedealPost',
	'exchangedealListPost' = 'exchangedealListPost',
	'exchangedealExecutePatch' = 'exchangedealExecutePatch',
}

/**
* @description ActionsMethods.
*/
export enum ActionsMethods {
	'actionsGet' = 'actionsGet',
}

/**
* @description AuditMethods.
*/
export enum AuditMethods {
	'eventPost' = 'eventPost',
	'reviewPost' = 'reviewPost',
	'runtimePost' = 'runtimePost',
}

/**
* @description AuthorizationMethods.
*/
export enum AuthorizationMethods {
	'oauthAuthorizePost' = 'oauthAuthorizePost',
	'oauthInfoGet' = 'oauthInfoGet',
	'oauthCheck_tokenPost' = 'oauthCheck_tokenPost',
	'oauthLoginPost' = 'oauthLoginPost',
	'oauthLogoutPatch' = 'oauthLogoutPatch',
	'oauthTokenPost' = 'oauthTokenPost',
	'oauthPinPost' = 'oauthPinPost',
	'credentialInitialPost' = 'credentialInitialPost',
	'credentialRegisterPost' = 'credentialRegisterPost',
	'credentialChallengePost' = 'credentialChallengePost',
}

/**
* @description AutocarMethods.
*/
export enum AutocarMethods {
	'autotekaReportVehiclePost' = 'autotekaReportVehiclePost',
	'autotekaReportVehicleGet' = 'autotekaReportVehicleGet',
	'carLoanInfoGet' = 'carLoanInfoGet',
	'dictionaryAutoBrandGet' = 'dictionaryAutoBrandGet',
	'dictionaryAutoModelGet' = 'dictionaryAutoModelGet',
	'dictionaryAutoModificationGet' = 'dictionaryAutoModificationGet',
	'dictionaryAutocardInfoGet' = 'dictionaryAutocardInfoGet',
	'dictionaryBankGet' = 'dictionaryBankGet',
	'dictionaryCredentialTypeGet' = 'dictionaryCredentialTypeGet',
	'dictionaryRegionGet' = 'dictionaryRegionGet',
	'dictionaryVehicleInfoGet' = 'dictionaryVehicleInfoGet',
	'fileAgreementGet' = 'fileAgreementGet',
	'insuranceInvoiceOverduePenaltyPost' = 'insuranceInvoiceOverduePenaltyPost',
	'issueGet' = 'issueGet',
	'issuePost' = 'issuePost',
	'issueAutoPatch' = 'issueAutoPatch',
	'issueCalculatePatch' = 'issueCalculatePatch',
	'issueConfirmPatch' = 'issueConfirmPatch',
	'issueDriversPatch' = 'issueDriversPatch',
	'issueImportPatch' = 'issueImportPatch',
	'issuePersonalDataPatch' = 'issuePersonalDataPatch',
	'paymentPost' = 'paymentPost',
	'paymentPut' = 'paymentPut',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
}

/**
* @description BillboardMethods.
*/
export enum BillboardMethods {
	'advertisingGet' = 'advertisingGet',
	'advertisingDelete' = 'advertisingDelete',
	'advertisingCountPut' = 'advertisingCountPut',
	'advertisingTapPut' = 'advertisingTapPut',
	'privilegeContentGet' = 'privilegeContentGet',
	'productcatalogContentGet' = 'productcatalogContentGet',
	'productcatalogStyleGet' = 'productcatalogStyleGet',
	'recommendedProductGet' = 'recommendedProductGet',
	'recommendedProductInvitationsGet' = 'recommendedProductInvitationsGet',
	'sliderContentGet' = 'sliderContentGet',
	'styleGet' = 'styleGet',
	'tetrisGet' = 'tetrisGet',
}

/**
* @description CallbackMethods.
*/
export enum CallbackMethods {
	'callbackPost' = 'callbackPost',
}

/**
* @description CardMethods.
*/
export enum CardMethods {
	'cardGet' = 'cardGet',
	'aliasGet' = 'aliasGet',
	'aliasPut' = 'aliasPut',
	'aliasDelete' = 'aliasDelete',
	'digitalAvailableGet' = 'digitalAvailableGet',
	'digitalCancelPatch' = 'digitalCancelPatch',
	'digitalIssuePost' = 'digitalIssuePost',
	'digitalIssuePut' = 'digitalIssuePut',
	'cardPut' = 'cardPut',
	'activatePost' = 'activatePost',
	'activateCanceledPatch' = 'activateCanceledPatch',
	'activateExecutedPatch' = 'activateExecutedPatch',
	'changeAccountPatch' = 'changeAccountPatch',
	'changepinPost' = 'changepinPost',
	'changepinCanceledPatch' = 'changepinCanceledPatch',
	'changepinExecutedPatch' = 'changepinExecutedPatch',
	'limitGet' = 'limitGet',
	'limitPatch' = 'limitPatch',
	'operationLimitGet' = 'operationLimitGet',
	'requisitesPost' = 'requisitesPost',
	'statusPatch' = 'statusPatch',
	'detailGet' = 'detailGet',
	'statusPost' = 'statusPost',
	'productUidDetailsGet' = 'productUidDetailsGet',
	'corporateGet' = 'corporateGet',
}

/**
* @description CardIssueMethods.
*/
export enum CardIssueMethods {
	'linkIssuePut' = 'linkIssuePut',
	'digitalIssuePut' = 'digitalIssuePut',
	'processStageChangedPost' = 'processStageChangedPost',
	'linkIssuePost' = 'linkIssuePost',
	'digitalIssuePost' = 'digitalIssuePost',
	'processStageChangedExecutedPatch' = 'processStageChangedExecutedPatch',
	'processStageChangedCancelledPatch' = 'processStageChangedCancelledPatch',
	'linkIssueExecutedPatch' = 'linkIssueExecutedPatch',
	'linkIssueCancelledPatch' = 'linkIssueCancelledPatch',
	'digitalCancelPatch' = 'digitalCancelPatch',
	'digitalAvailableGet' = 'digitalAvailableGet',
	'cardOrderGet' = 'cardOrderGet',
	'cardOrderOrderGet' = 'cardOrderOrderGet',
	'cardOrderDeliveryCardsGet' = 'cardOrderDeliveryCardsGet',
}

/**
* @description CardPayMethods.
*/
export enum CardPayMethods {
	'paymentGet' = 'paymentGet',
	'paymentPut' = 'paymentPut',
	'paymentPatch' = 'paymentPatch',
	'twpgPost' = 'twpgPost',
	'paymentPost' = 'paymentPost',
	'paymentTwpgPost' = 'paymentTwpgPost',
	'paymentByTemplatePost' = 'paymentByTemplatePost',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'paymentCresPatch' = 'paymentCresPatch',
	'paymentAreqPatch' = 'paymentAreqPatch',
	'paymentPartialUpdatePatch' = 'paymentPartialUpdatePatch',
	'paymentStatusGet' = 'paymentStatusGet',
	'checkCountryGet' = 'checkCountryGet',
	'cardInfoGet' = 'cardInfoGet',
	'cardInfoClientGet' = 'cardInfoClientGet',
	'aliasInfoClientGet' = 'aliasInfoClientGet',
}

/**
* @description CarloanMethods.
*/
export enum CarloanMethods {
	'cardOrderPost' = 'cardOrderPost',
	'finServiceRequestPost' = 'finServiceRequestPost',
	'notesGet' = 'notesGet',
	'periodProductActionShowGet' = 'periodProductActionShowGet',
}

/**
* @description ChargeMethods.
*/
export enum ChargeMethods {
	'chargeGet' = 'chargeGet',
	'clientGet' = 'clientGet',
	'countGet' = 'countGet',
	'currentUpcomingExpensesGet' = 'currentUpcomingExpensesGet',
	'gisgmpPost' = 'gisgmpPost',
	'giszkhPost' = 'giszkhPost',
	'mortgagePaymentsGet' = 'mortgagePaymentsGet',
	'subscriptionGet' = 'subscriptionGet',
	'subscriptionPost' = 'subscriptionPost',
	'subscriptionPut' = 'subscriptionPut',
	'subscriptionDelete' = 'subscriptionDelete',
	'vendorsGet' = 'vendorsGet',
	'chargePatch' = 'chargePatch',
}

/**
* @description ChargepayMethods.
*/
export enum ChargepayMethods {
	'paymentPost' = 'paymentPost',
	'paymentGet' = 'paymentGet',
	'paymentPut' = 'paymentPut',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'invoicePost' = 'invoicePost',
	'invoicePut' = 'invoicePut',
}

/**
* @description ChronologyMethods.
*/
export enum ChronologyMethods {
	'chronologyGet' = 'chronologyGet',
	'pageGet' = 'pageGet',
	'pageVersionGet' = 'pageVersionGet',
	'paymentGet' = 'paymentGet',
	'paymentCountGet' = 'paymentCountGet',
	'paymentRepeatGet' = 'paymentRepeatGet',
	'paymentPost' = 'paymentPost',
	'paymentHidePatch' = 'paymentHidePatch',
	'pfmGet' = 'pfmGet',
	'pfmCategoryGet' = 'pfmCategoryGet',
	'pfmGroupGet' = 'pfmGroupGet',
	'receiptGet' = 'receiptGet',
}

/**
* @description ClaimsMethods.
*/
export enum ClaimsMethods {
	'clientIssuePost' = 'clientIssuePost',
	'clientIssueExecutePatch' = 'clientIssueExecutePatch',
	'clientPersonalDataAddressTypeGet' = 'clientPersonalDataAddressTypeGet',
	'clientPersonalDataAvailablePersonalRequisitesGet' = 'clientPersonalDataAvailablePersonalRequisitesGet',
	'clientPersonalDataIssuePost' = 'clientPersonalDataIssuePost',
	'clientPersonalDataIssueExecutePatch' = 'clientPersonalDataIssueExecutePatch',
	'clientPersonalDataResidenceTypeGet' = 'clientPersonalDataResidenceTypeGet',
	'issuePost' = 'issuePost',
	'issueExecutedPatch' = 'issueExecutedPatch',
	'operationContestPost' = 'operationContestPost',
	'operationContestCasesGet' = 'operationContestCasesGet',
	'operationContestExecutePatch' = 'operationContestExecutePatch',
	'paymentDetailsIssuePost' = 'paymentDetailsIssuePost',
	'paymentDetailsIssueExecutedPatch' = 'paymentDetailsIssueExecutedPatch',
	'personalStatementPatch' = 'personalStatementPatch',
	'statementGet' = 'statementGet',
	'statementPatch' = 'statementPatch',
	'statementAccountStatementPost' = 'statementAccountStatementPost',
	'statementAccountStatementFilePost' = 'statementAccountStatementFilePost',
	'statementAccountStatementFileGet' = 'statementAccountStatementFileGet',
	'statementAccountStatementGet' = 'statementAccountStatementGet',
	'statementAvailableStatementsGet' = 'statementAvailableStatementsGet',
	'statementParamsGet' = 'statementParamsGet',
	'statementPersonalAvailableStatementsGet' = 'statementPersonalAvailableStatementsGet',
	'typeGet' = 'typeGet',
}

/**
* @description ClientCabinetMethods.
*/
export enum ClientCabinetMethods {
	'contentGet' = 'contentGet',
	'contentViewPut' = 'contentViewPut',
	'markersGet' = 'markersGet',
}

/**
* @description ClosingAccountMethods.
*/
export enum ClosingAccountMethods {
	'documentPost' = 'documentPost',
	'documentExecutePatch' = 'documentExecutePatch',
	'currencyCheckGet' = 'currencyCheckGet',
	'checkGet' = 'checkGet',
}

/**
* @description CommunicationMethods.
*/
export enum CommunicationMethods {
	'communicationGet' = 'communicationGet',
	'addPost' = 'addPost',
	'addProfileInfoGet' = 'addProfileInfoGet',
	'deletePost' = 'deletePost',
	'editPost' = 'editPost',
	'editProfileInfoGet' = 'editProfileInfoGet',
}

/**
* @description ConciergeMethods.
*/
export enum ConciergeMethods {
	'issuePost' = 'issuePost',
	'emailPost' = 'emailPost',
	'contentGet' = 'contentGet',
}

/**
* @description CourierDeliveryMethods.
*/
export enum CourierDeliveryMethods {
	'timeslotsGet' = 'timeslotsGet',
	'orderPost' = 'orderPost',
	'orderPut' = 'orderPut',
	'orderPatch' = 'orderPatch',
	'orderGet' = 'orderGet',
	'orderDetailsGet' = 'orderDetailsGet',
	'orderStatusPatch' = 'orderStatusPatch',
	'updatePost' = 'updatePost',
	'cardDeliveryCitiesPut' = 'cardDeliveryCitiesPut',
	'cardDeliveryCitiesPost' = 'cardDeliveryCitiesPost',
	'cardDeliveryCitiesGet' = 'cardDeliveryCitiesGet',
	'cardDeliveryCitiesDelete' = 'cardDeliveryCitiesDelete',
	'cardDeliveryCitiesPackagePost' = 'cardDeliveryCitiesPackagePost',
}

/**
* @description CreditcardMethods.
*/
export enum CreditcardMethods {
	'installmentPut' = 'installmentPut',
	'installmentDelete' = 'installmentDelete',
	'installmentPost' = 'installmentPost',
	'deliveryPost' = 'deliveryPost',
	'installmentReducedPatch' = 'installmentReducedPatch',
	'installmentExecutedPatch' = 'installmentExecutedPatch',
	'installmentAgreementSignedGet' = 'installmentAgreementSignedGet',
	'installmentAgreementPreviewGet' = 'installmentAgreementPreviewGet',
}

/**
* @description CreditpayMethods.
*/
export enum CreditpayMethods {
	'accountNumberPaymentTypeGet' = 'accountNumberPaymentTypeGet',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'privatePaymentPost' = 'privatePaymentPost',
	'privatePaymentPartialUpdatePatch' = 'privatePaymentPartialUpdatePatch',
	'privatePaymentPut' = 'privatePaymentPut',
	'productPaymentTypeGet' = 'productPaymentTypeGet',
	'authorizationGet' = 'authorizationGet',
	'authorizationPost' = 'authorizationPost',
	'authorizationAuthorizedPatch' = 'authorizationAuthorizedPatch',
	'documentPost' = 'documentPost',
	'documentPartialUpdatePatch' = 'documentPartialUpdatePatch',
	'documentGet' = 'documentGet',
	'documentConfirmedPatch' = 'documentConfirmedPatch',
	'orderPost' = 'orderPost',
	'orderExecutedPatch' = 'orderExecutedPatch',
}

/**
* @description CurrentAccountMethods.
*/
export enum CurrentAccountMethods {
	'accountIssuePost' = 'accountIssuePost',
	'accountIssuePut' = 'accountIssuePut',
	'accountIssueExecutedPatch' = 'accountIssueExecutedPatch',
	'openAccountInfoGet' = 'openAccountInfoGet',
}

/**
* @description CustomerinfoMethods.
*/
export enum CustomerinfoMethods {
	'agreementGet' = 'agreementGet',
	'agreementApplyPost' = 'agreementApplyPost',
	'clientInfoGet' = 'clientInfoGet',
	'clientsAccountGet' = 'clientsAccountGet',
	'clientsCardGet' = 'clientsCardGet',
	'consentGet' = 'consentGet',
	'consentPost' = 'consentPost',
	'consentPathGet' = 'consentPathGet',
	'contactsPost' = 'contactsPost',
	'crmGet' = 'crmGet',
	'defaultbankGet' = 'defaultbankGet',
	'defaultbankStatusGet' = 'defaultbankStatusGet',
	'defaultbankPatch' = 'defaultbankPatch',
	'ipssubscriptionsSettingsGet' = 'ipssubscriptionsSettingsGet',
	'ipssubscriptionsSettingsPut' = 'ipssubscriptionsSettingsPut',
	'ipssubscriptionsSettingsDelete' = 'ipssubscriptionsSettingsDelete',
	'managerGet' = 'managerGet',
	'me2meSettingsGet' = 'me2meSettingsGet',
	'me2meSettingsPut' = 'me2meSettingsPut',
	'me2meSettingsPost' = 'me2meSettingsPost',
	'me2meSettingsDelete' = 'me2meSettingsDelete',
	'me2meSettingsPatch' = 'me2meSettingsPatch',
	'me2meSettingsAllDelete' = 'me2meSettingsAllDelete',
	'productSettingsPut' = 'productSettingsPut',
	'productSettingsMainScreenPut' = 'productSettingsMainScreenPut',
	'profileGet' = 'profileGet',
	'profileDetailsGet' = 'profileDetailsGet',
	'quickactionsGet' = 'quickactionsGet',
	'sbpayDependenceGet' = 'sbpayDependenceGet',
	'sbpaySettingsGet' = 'sbpaySettingsGet',
	'sbpaySettingsPut' = 'sbpaySettingsPut',
	'sbpaySettingsPost' = 'sbpaySettingsPost',
	'sbpaySettingsDelete' = 'sbpaySettingsDelete',
	'segmentGet' = 'segmentGet',
	'settingsGet' = 'settingsGet',
	'settingsPost' = 'settingsPost',
	'settingsDelete' = 'settingsDelete',
	'settingsPatch' = 'settingsPatch',
	'softtokenActivatePost' = 'softtokenActivatePost',
	'softtokenDeactivatePost' = 'softtokenDeactivatePost',
	'softtokenGeneratePost' = 'softtokenGeneratePost',
	'softtokenStatusGet' = 'softtokenStatusGet',
	'userLoginPatch' = 'userLoginPatch',
	'userGet' = 'userGet',
	'clientsCardFullResponseGet' = 'clientsCardFullResponseGet',
	'consentsGet' = 'consentsGet',
	'consentsPost' = 'consentsPost',
	'deviceGet' = 'deviceGet',
	'deviceChangePatch' = 'deviceChangePatch',
	'deviceDeactivatePatch' = 'deviceDeactivatePatch',
	'extendedUserPatch' = 'extendedUserPatch',
	'extendedUserInfoGet' = 'extendedUserInfoGet',
	'legalAccountGet' = 'legalAccountGet',
	'legalInfoGet' = 'legalInfoGet',
}

/**
* @description DeeplinkMethods.
*/
export enum DeeplinkMethods {
	'longGet' = 'longGet',
	'universalGet' = 'universalGet',
}

/**
* @description DemandpayMethods.
*/
export enum DemandpayMethods {
	'insuranceCompanyGet' = 'insuranceCompanyGet',
	'insuranceRequisitesPost' = 'insuranceRequisitesPost',
	'legalPaymentPost' = 'legalPaymentPost',
	'legalPaymentPut' = 'legalPaymentPut',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
}

/**
* @description DialogsMethods.
*/
export enum DialogsMethods {
	'chatMessagesPut' = 'chatMessagesPut',
	'omnichatTokenPost' = 'omnichatTokenPost',
	'omnichatActionPost' = 'omnichatActionPost',
	'chatMessagePost' = 'chatMessagePost',
	'omnichatSettingGet' = 'omnichatSettingGet',
	'chatGet' = 'chatGet',
	'chatDetailsGet' = 'chatDetailsGet',
	'chatDelete' = 'chatDelete',
}

/**
* @description FileUploadMethods.
*/
export enum FileUploadMethods {
	'fileUploadGet' = 'fileUploadGet',
	'fileUploadPost' = 'fileUploadPost',
}

/**
* @description FinanceMethods.
*/
export enum FinanceMethods {
	'carloanLinkPost' = 'carloanLinkPost',
	'financePatch' = 'financePatch',
	'mortgageGet' = 'mortgageGet',
	'mortgagePatch' = 'mortgagePatch',
	'carloanGet' = 'carloanGet',
	'carloanPatch' = 'carloanPatch',
	'financeGet' = 'financeGet',
	'restructuringLinkGet' = 'restructuringLinkGet',
	'requisitesGet' = 'requisitesGet',
	'detailsGet' = 'detailsGet',
	'actionsGet' = 'actionsGet',
	'savingsGet' = 'savingsGet',
	'savingsDetailsGet' = 'savingsDetailsGet',
	'mortgageLinkGet' = 'mortgageLinkGet',
	'investRedemptionSumsGet' = 'investRedemptionSumsGet',
	'investPaymentScheduleGet' = 'investPaymentScheduleGet',
	'investDetailsGet' = 'investDetailsGet',
	'insuranceGet' = 'insuranceGet',
	'insuranceHintGet' = 'insuranceHintGet',
	'depositGet' = 'depositGet',
	'currentGet' = 'currentGet',
	'currentDetailsGet' = 'currentDetailsGet',
	'creditGet' = 'creditGet',
	'creditScheduleGet' = 'creditScheduleGet',
	'carloanLinkGet' = 'carloanLinkGet',
	'accountGet' = 'accountGet',
}

/**
* @description InstantpayMethods.
*/
export enum InstantpayMethods {
	'personPhoneGet' = 'personPhoneGet',
	'personPhonePut' = 'personPhonePut',
	'personPhonePatch' = 'personPhonePatch',
	'inputDocumentPut' = 'inputDocumentPut',
	'personPhonePost' = 'personPhonePost',
	'personPhoneTechnicalPost' = 'personPhoneTechnicalPost',
	'personPhoneByTemplatePost' = 'personPhoneByTemplatePost',
	'outputCheckPost' = 'outputCheckPost',
	'inputDocumentPost' = 'inputDocumentPost',
	'directLinkPost' = 'directLinkPost',
	'personPhoneSettledPatch' = 'personPhoneSettledPatch',
	'personPhoneExecutedPatch' = 'personPhoneExecutedPatch',
	'outputDocumentUpdatePatch' = 'outputDocumentUpdatePatch',
	'outputDocumentExecutePatch' = 'outputDocumentExecutePatch',
	'outputDocumentCancelPatch' = 'outputDocumentCancelPatch',
	'inputDocumentConfirmPayPatch' = 'inputDocumentConfirmPayPatch',
	'phoneinfoPayeeGet' = 'phoneinfoPayeeGet',
	'outputDocumentGet' = 'outputDocumentGet',
	'memberMe2meGet' = 'memberMe2meGet',
	'freeLimitGet' = 'freeLimitGet',
	'banklistGet' = 'banklistGet',
	'countryCodeGet' = 'countryCodeGet',
}

/**
* @description LegalpayMethods.
*/
export enum LegalpayMethods {
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'paymentFeecalculatePut' = 'paymentFeecalculatePut',
	'paymentSettledPatch' = 'paymentSettledPatch',
	'sbpC2bAwPost' = 'sbpC2bAwPost',
	'acknowledgementPatch' = 'acknowledgementPatch',
	'petitionPost' = 'petitionPost',
}

/**
* @description LimitMethods.
*/
export enum LimitMethods {
	'customerGet' = 'customerGet',
	'customerSingleLimitGet' = 'customerSingleLimitGet',
}

/**
* @description LocalizationMethods.
*/
export enum LocalizationMethods {
	'localization_channel_Post' = 'localization_channel_Post',
	'localization_channel_Key_key_Get' = 'localization_channel_Key_key_Get',
	'localizationVersion_version_Get' = 'localizationVersion_version_Get',
}

/**
* @description LoyaltyMethods.
*/
export enum LoyaltyMethods {
	'loyaltyGet' = 'loyaltyGet',
	'bannersGet' = 'bannersGet',
	'bonushistoryGet' = 'bonushistoryGet',
	'bonushistoryPageGet' = 'bonushistoryPageGet',
	'cardGet' = 'cardGet',
	'cardCategoryGet' = 'cardCategoryGet',
	'cardPost' = 'cardPost',
	'categoriesGet' = 'categoriesGet',
	'categoryOffersGet' = 'categoryOffersGet',
	'memberProgramGet' = 'memberProgramGet',
	'memberProgramBalanceInfoGet' = 'memberProgramBalanceInfoGet',
	'offersGet' = 'offersGet',
	'offersFavouriteGet' = 'offersFavouriteGet',
	'offersDetailsGet' = 'offersDetailsGet',
	'offersFavouritePut' = 'offersFavouritePut',
	'offersFavouritePost' = 'offersFavouritePost',
	'operationsGet' = 'operationsGet',
	'travelurlGet' = 'travelurlGet',
	'actionGet' = 'actionGet',
	'categoryGet' = 'categoryGet',
	'categoryPatch' = 'categoryPatch',
	'deactivatePatch' = 'deactivatePatch',
	'graphGet' = 'graphGet',
	'ruleGet' = 'ruleGet',
	'switchPatch' = 'switchPatch',
	'programFAQGet' = 'programFAQGet',
}

/**
* @description MortgageMethods.
*/
export enum MortgageMethods {
	'cardOrderPost' = 'cardOrderPost',
	'mortgageGet' = 'mortgageGet',
	'mortgagePatch' = 'mortgagePatch',
	'linkGet' = 'linkGet',
	'accountRequisitesGet' = 'accountRequisitesGet',
	'paymentsGet' = 'paymentsGet',
}

/**
* @description NotificationMethods.
*/
export enum NotificationMethods {
	'notificationGet' = 'notificationGet',
	'deletedPut' = 'deletedPut',
	'documentListGet' = 'documentListGet',
	'informedPut' = 'informedPut',
	'messageDeletedPut' = 'messageDeletedPut',
	'messageHistoryGet' = 'messageHistoryGet',
	'messageHistoryReadPatch' = 'messageHistoryReadPatch',
	'readPut' = 'readPut',
	'splashAllGet' = 'splashAllGet',
	'splashStyleGet' = 'splashStyleGet',
	'splashBackwardedPatch' = 'splashBackwardedPatch',
	'splashClosedPatch' = 'splashClosedPatch',
	'splashInformedPatch' = 'splashInformedPatch',
	'splashReadPatch' = 'splashReadPatch',
	'technicalGet' = 'technicalGet',
	'technicalCountPut' = 'technicalCountPut',
}

/**
* @description OperationTemplateMethods.
*/
export enum OperationTemplateMethods {
	'templateGet' = 'templateGet',
	'templatePut' = 'templatePut',
	'templateDelete' = 'templateDelete',
	'templatePatch' = 'templatePatch',
	'templatePost' = 'templatePost',
	'statementPost' = 'statementPost',
	'statementCancelledPost' = 'statementCancelledPost',
	'regularpaymentorderSuspendPatch' = 'regularpaymentorderSuspendPatch',
	'vendorGet' = 'vendorGet',
	'vendorDetailsGet' = 'vendorDetailsGet',
	'logoGet' = 'logoGet',
}

/**
* @description PaperlessOfficeMethods.
*/
export enum PaperlessOfficeMethods {
	'documentPost' = 'documentPost',
	'documentGet' = 'documentGet',
	'issuePost' = 'issuePost',
	'issueExecutePatch' = 'issueExecutePatch',
	'relatedProductsGet' = 'relatedProductsGet',
	'relatedProductsConfirmPatch' = 'relatedProductsConfirmPatch',
}

/**
* @description ProductCatalogMethods.
*/
export enum ProductCatalogMethods {
	'contentsGet' = 'contentsGet',
	'digitalCardOrderAvailableGet' = 'digitalCardOrderAvailableGet',
	'feedGet' = 'feedGet',
	'propositionContentGet' = 'propositionContentGet',
	'propositionStyleGet' = 'propositionStyleGet',
	'styleGet' = 'styleGet',
}

/**
* @description PushMethods.
*/
export enum PushMethods {
	'devicePost' = 'devicePost',
	'messageStatusPost' = 'messageStatusPost',
	'mfmDevicePatch' = 'mfmDevicePatch',
	'mfmDeviceGet' = 'mfmDeviceGet',
}

/**
* @description QrrecognitionMethods.
*/
export enum QrrecognitionMethods {
	'recognitionPost' = 'recognitionPost',
}

/**
* @description RegistrationMethods.
*/
export enum RegistrationMethods {
	'registrationPost' = 'registrationPost',
	'webbyConfirmPost' = 'webbyConfirmPost',
	'webbyOfferPost' = 'webbyOfferPost',
	'webbyOfferAgreementPost' = 'webbyOfferAgreementPost',
	'webbyOfferGet' = 'webbyOfferGet',
}

/**
* @description RepaymentMethods.
*/
export enum RepaymentMethods {
	'repaymentGet' = 'repaymentGet',
	'repaymentPut' = 'repaymentPut',
	'repaymentPost' = 'repaymentPost',
	'executedPatch' = 'executedPatch',
}

/**
* @description RequisitePayMethods.
*/
export enum RequisitePayMethods {
	'privatePaymentGet' = 'privatePaymentGet',
	'privatePaymentPut' = 'privatePaymentPut',
	'privatePaymentPatch' = 'privatePaymentPatch',
	'privatePaymentPost' = 'privatePaymentPost',
	'legalPaymentGet' = 'legalPaymentGet',
	'legalPaymentPut' = 'legalPaymentPut',
	'legalPaymentPatch' = 'legalPaymentPatch',
	'legalPaymentPost' = 'legalPaymentPost',
	'budgetPaymentPost' = 'budgetPaymentPost',
	'budgetPaymentGet' = 'budgetPaymentGet',
	'budgetPaymentPut' = 'budgetPaymentPut',
	'budgetPaymentPatch' = 'budgetPaymentPatch',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'dictionaryBicUniversalGet' = 'dictionaryBicUniversalGet',
	'clientInnGet' = 'clientInnGet',
	'dictionaryBudgetGet' = 'dictionaryBudgetGet',
	'clientDetailsGet' = 'clientDetailsGet',
	'dictionaryPayee_typeGet' = 'dictionaryPayee_typeGet',
	'budgetPaymentByTemplatePost' = 'budgetPaymentByTemplatePost',
	'legalPaymentByTemplatePost' = 'legalPaymentByTemplatePost',
	'privatePaymentByTemplatePost' = 'privatePaymentByTemplatePost',
	'contractPaymentPut' = 'contractPaymentPut',
	'contractPaymentPost' = 'contractPaymentPost',
	'contractPaymentByTemplatePost' = 'contractPaymentByTemplatePost',
	'privatePaymentPartialUpdatePatch' = 'privatePaymentPartialUpdatePatch',
	'legalPaymentPartialUpdatePatch' = 'legalPaymentPartialUpdatePatch',
	'contractPaymentExecutedPatch' = 'contractPaymentExecutedPatch',
	'budgetPaymentPartialUpdatePatch' = 'budgetPaymentPartialUpdatePatch',
	'dictionaryCompanyGet' = 'dictionaryCompanyGet',
	'dictionaryBicGet' = 'dictionaryBicGet',
	'dictionaryBicRuledGet' = 'dictionaryBicRuledGet',
}

/**
* @description SaleMethods.
*/
export enum SaleMethods {
	'authForwardGet' = 'authForwardGet',
	'authForwardPost' = 'authForwardPost',
	'authP2uGet' = 'authP2uGet',
	'cardUpdateGet' = 'cardUpdateGet',
	'staticStyleGet' = 'staticStyleGet',
	'staticStyleHead' = 'staticStyleHead',
	'staticStylePost' = 'staticStylePost',
	'staticStylePut' = 'staticStylePut',
	'staticStyleDelete' = 'staticStyleDelete',
	'staticStyleOptions' = 'staticStyleOptions',
	'staticStylePatch' = 'staticStylePatch',
}

/**
* @description SavingMethods.
*/
export enum SavingMethods {
	'accountIssuePost' = 'accountIssuePost',
	'accountIssuePut' = 'accountIssuePut',
	'accountIssueExecutedPatch' = 'accountIssueExecutedPatch',
	'accountBannerGet' = 'accountBannerGet',
	'bonusAccountsGet' = 'bonusAccountsGet',
	'bonusConditionGet' = 'bonusConditionGet',
	'bonusDescriptionGet' = 'bonusDescriptionGet',
	'bonusMccGet' = 'bonusMccGet',
	'bonusDetailsGet' = 'bonusDetailsGet',
	'bonusHistoryGet' = 'bonusHistoryGet',
	'bonusOrderPost' = 'bonusOrderPost',
	'depositCalculationGet' = 'depositCalculationGet',
	'depositCloseDepositIssueExecutedPatch' = 'depositCloseDepositIssueExecutedPatch',
	'depositDocumentPut' = 'depositDocumentPut',
	'depositDocumentConditionsGet' = 'depositDocumentConditionsGet',
	'depositIssuePost' = 'depositIssuePost',
	'depositIssuePut' = 'depositIssuePut',
	'depositIssueExecutedPatch' = 'depositIssueExecutedPatch',
	'depositIssueFormGet' = 'depositIssueFormGet',
	'depositIssuePreconditionGet' = 'depositIssuePreconditionGet',
	'depositIssueSearchGet' = 'depositIssueSearchGet',
	'depositIssueThresholdGet' = 'depositIssueThresholdGet',
	'depositOrderPost' = 'depositOrderPost',
	'depositProfitGet' = 'depositProfitGet',
	'depositWithdrawIssueExecutedPatch' = 'depositWithdrawIssueExecutedPatch',
	'depositCloseDepositIssuePost' = 'depositCloseDepositIssuePost',
	'depositWithdrawIssuePost' = 'depositWithdrawIssuePost',
	'depositConditionsPdfPreparationGet' = 'depositConditionsPdfPreparationGet',
	'depositConditionsPdfGet' = 'depositConditionsPdfGet',
	'openAccountInfoGet' = 'openAccountInfoGet',
}

/**
* @description ServicePayMethods.
*/
export enum ServicePayMethods {
	'directInvoicePost' = 'directInvoicePost',
	'directInvoicePut' = 'directInvoicePut',
	'directPaymentPost' = 'directPaymentPost',
	'directPaymentGet' = 'directPaymentGet',
	'directPaymentPut' = 'directPaymentPut',
	'directPaymentExecutedPatch' = 'directPaymentExecutedPatch',
	'invoicePost' = 'invoicePost',
	'invoicePut' = 'invoicePut',
	'paymentPost' = 'paymentPost',
	'paymentGet' = 'paymentGet',
	'paymentPut' = 'paymentPut',
	'paymentExecutedPatch' = 'paymentExecutedPatch',
	'paymentRepeatGet' = 'paymentRepeatGet',
}

/**
* @description ServicePointMethods.
*/
export enum ServicePointMethods {
	'listPost' = 'listPost',
	'mapPost' = 'mapPost',
	'officeDetailsGet' = 'officeDetailsGet',
	'officeTimeTableGet' = 'officeTimeTableGet',
}

/**
* @description StatementMethods.
*/
export enum StatementMethods {
	'personalPost' = 'personalPost',
	'accountStatementPost' = 'accountStatementPost',
	'accountStatementFilePost' = 'accountStatementFilePost',
	'statementGet' = 'statementGet',
	'personalAvailableStatementsGet' = 'personalAvailableStatementsGet',
	'cardStatementGet' = 'cardStatementGet',
	'accountStatementGet' = 'accountStatementGet',
	'accountStatementFileGet' = 'accountStatementFileGet',
}

/**
* @description TariffMethods.
*/
export enum TariffMethods {
	'priorityGet' = 'priorityGet',
	'priorityPassesGet' = 'priorityPassesGet',
	'tariffGet' = 'tariffGet',
	'tariffReceiptGet' = 'tariffReceiptGet',
}

/**
* @description VendorMethods.
*/
export enum VendorMethods {
	'categoryGet' = 'categoryGet',
	'categoryProductGet' = 'categoryProductGet',
	'productGet' = 'productGet',
	'productSettingsGet' = 'productSettingsGet',
}


/**
* @description ServiceMethods.
*/
export const ServiceMethods = {
    
    accountPay: {
		accountAccountNumberGet: 'accountAccountNumberGet',
		accountCardNumberGet: 'accountCardNumberGet',
		accountPaymentGet: 'accountPaymentGet',
		accountPhoneGet: 'accountPhoneGet',
		clientAccountPost: 'clientAccountPost',
		clientAccountByTemplatePost: 'clientAccountByTemplatePost',
		clientAccountGet: 'clientAccountGet',
		clientAccountPut: 'clientAccountPut',
		clientAccountPatch: 'clientAccountPatch',
		clientAccountExecutedPatch: 'clientAccountExecutedPatch',
		clientPartialUpdatePatch: 'clientPartialUpdatePatch',
		depositPost: 'depositPost',
		depositMinimumContributionGet: 'depositMinimumContributionGet',
		depositPut: 'depositPut',
		depositExecutedPatch: 'depositExecutedPatch',
		exchangePost: 'exchangePost',
		exchangeMainCurrencyGet: 'exchangeMainCurrencyGet',
		exchangeGet: 'exchangeGet',
		exchangePut: 'exchangePut',
		exchangePatch: 'exchangePatch',
		exchangeExecutedPatch: 'exchangeExecutedPatch',
		ownPost: 'ownPost',
		ownByTemplatePost: 'ownByTemplatePost',
		ownPartialUpdatePatch: 'ownPartialUpdatePatch',
		ownGet: 'ownGet',
		ownPut: 'ownPut',
		ownPatch: 'ownPatch',
		ownExecutedPatch: 'ownExecutedPatch',
		exchangedealGet: 'exchangedealGet',
		exchangedealPut: 'exchangedealPut',
		exchangedealPost: 'exchangedealPost',
		exchangedealListPost: 'exchangedealListPost',
		exchangedealExecutePatch: 'exchangedealExecutePatch',
    },

    actions: {
		actionsGet: 'actionsGet',
    },

    audit: {
		eventPost: 'eventPost',
		reviewPost: 'reviewPost',
		runtimePost: 'runtimePost',
    },

    authorization: {
		oauthAuthorizePost: 'oauthAuthorizePost',
		oauthInfoGet: 'oauthInfoGet',
		oauthCheck_tokenPost: 'oauthCheck_tokenPost',
		oauthLoginPost: 'oauthLoginPost',
		oauthLogoutPatch: 'oauthLogoutPatch',
		oauthTokenPost: 'oauthTokenPost',
		oauthPinPost: 'oauthPinPost',
		credentialInitialPost: 'credentialInitialPost',
		credentialRegisterPost: 'credentialRegisterPost',
		credentialChallengePost: 'credentialChallengePost',
    },

    autocar: {
		autotekaReportVehiclePost: 'autotekaReportVehiclePost',
		autotekaReportVehicleGet: 'autotekaReportVehicleGet',
		carLoanInfoGet: 'carLoanInfoGet',
		dictionaryAutoBrandGet: 'dictionaryAutoBrandGet',
		dictionaryAutoModelGet: 'dictionaryAutoModelGet',
		dictionaryAutoModificationGet: 'dictionaryAutoModificationGet',
		dictionaryAutocardInfoGet: 'dictionaryAutocardInfoGet',
		dictionaryBankGet: 'dictionaryBankGet',
		dictionaryCredentialTypeGet: 'dictionaryCredentialTypeGet',
		dictionaryRegionGet: 'dictionaryRegionGet',
		dictionaryVehicleInfoGet: 'dictionaryVehicleInfoGet',
		fileAgreementGet: 'fileAgreementGet',
		insuranceInvoiceOverduePenaltyPost: 'insuranceInvoiceOverduePenaltyPost',
		issueGet: 'issueGet',
		issuePost: 'issuePost',
		issueAutoPatch: 'issueAutoPatch',
		issueCalculatePatch: 'issueCalculatePatch',
		issueConfirmPatch: 'issueConfirmPatch',
		issueDriversPatch: 'issueDriversPatch',
		issueImportPatch: 'issueImportPatch',
		issuePersonalDataPatch: 'issuePersonalDataPatch',
		paymentPost: 'paymentPost',
		paymentPut: 'paymentPut',
		paymentExecutedPatch: 'paymentExecutedPatch',
    },

    billboard: {
		advertisingGet: 'advertisingGet',
		advertisingDelete: 'advertisingDelete',
		advertisingCountPut: 'advertisingCountPut',
		advertisingTapPut: 'advertisingTapPut',
		privilegeContentGet: 'privilegeContentGet',
		productcatalogContentGet: 'productcatalogContentGet',
		productcatalogStyleGet: 'productcatalogStyleGet',
		recommendedProductGet: 'recommendedProductGet',
		recommendedProductInvitationsGet: 'recommendedProductInvitationsGet',
		sliderContentGet: 'sliderContentGet',
		styleGet: 'styleGet',
		tetrisGet: 'tetrisGet',
    },

    callback: {
		callbackPost: 'callbackPost',
    },

    card: {
		cardGet: 'cardGet',
		aliasGet: 'aliasGet',
		aliasPut: 'aliasPut',
		aliasDelete: 'aliasDelete',
		digitalAvailableGet: 'digitalAvailableGet',
		digitalCancelPatch: 'digitalCancelPatch',
		digitalIssuePost: 'digitalIssuePost',
		digitalIssuePut: 'digitalIssuePut',
		cardPut: 'cardPut',
		activatePost: 'activatePost',
		activateCanceledPatch: 'activateCanceledPatch',
		activateExecutedPatch: 'activateExecutedPatch',
		changeAccountPatch: 'changeAccountPatch',
		changepinPost: 'changepinPost',
		changepinCanceledPatch: 'changepinCanceledPatch',
		changepinExecutedPatch: 'changepinExecutedPatch',
		limitGet: 'limitGet',
		limitPatch: 'limitPatch',
		operationLimitGet: 'operationLimitGet',
		requisitesPost: 'requisitesPost',
		statusPatch: 'statusPatch',
		detailGet: 'detailGet',
		statusPost: 'statusPost',
		productUidDetailsGet: 'productUidDetailsGet',
		corporateGet: 'corporateGet',
    },

    cardIssue: {
		linkIssuePut: 'linkIssuePut',
		digitalIssuePut: 'digitalIssuePut',
		processStageChangedPost: 'processStageChangedPost',
		linkIssuePost: 'linkIssuePost',
		digitalIssuePost: 'digitalIssuePost',
		processStageChangedExecutedPatch: 'processStageChangedExecutedPatch',
		processStageChangedCancelledPatch: 'processStageChangedCancelledPatch',
		linkIssueExecutedPatch: 'linkIssueExecutedPatch',
		linkIssueCancelledPatch: 'linkIssueCancelledPatch',
		digitalCancelPatch: 'digitalCancelPatch',
		digitalAvailableGet: 'digitalAvailableGet',
		cardOrderGet: 'cardOrderGet',
		cardOrderOrderGet: 'cardOrderOrderGet',
		cardOrderDeliveryCardsGet: 'cardOrderDeliveryCardsGet',
    },

    cardPay: {
		paymentGet: 'paymentGet',
		paymentPut: 'paymentPut',
		paymentPatch: 'paymentPatch',
		twpgPost: 'twpgPost',
		paymentPost: 'paymentPost',
		paymentTwpgPost: 'paymentTwpgPost',
		paymentByTemplatePost: 'paymentByTemplatePost',
		paymentExecutedPatch: 'paymentExecutedPatch',
		paymentCresPatch: 'paymentCresPatch',
		paymentAreqPatch: 'paymentAreqPatch',
		paymentPartialUpdatePatch: 'paymentPartialUpdatePatch',
		paymentStatusGet: 'paymentStatusGet',
		checkCountryGet: 'checkCountryGet',
		cardInfoGet: 'cardInfoGet',
		cardInfoClientGet: 'cardInfoClientGet',
		aliasInfoClientGet: 'aliasInfoClientGet',
    },

    carloan: {
		cardOrderPost: 'cardOrderPost',
		finServiceRequestPost: 'finServiceRequestPost',
		notesGet: 'notesGet',
		periodProductActionShowGet: 'periodProductActionShowGet',
    },

    charge: {
		chargeGet: 'chargeGet',
		clientGet: 'clientGet',
		countGet: 'countGet',
		currentUpcomingExpensesGet: 'currentUpcomingExpensesGet',
		gisgmpPost: 'gisgmpPost',
		giszkhPost: 'giszkhPost',
		mortgagePaymentsGet: 'mortgagePaymentsGet',
		subscriptionGet: 'subscriptionGet',
		subscriptionPost: 'subscriptionPost',
		subscriptionPut: 'subscriptionPut',
		subscriptionDelete: 'subscriptionDelete',
		vendorsGet: 'vendorsGet',
		chargePatch: 'chargePatch',
    },

    chargepay: {
		paymentPost: 'paymentPost',
		paymentGet: 'paymentGet',
		paymentPut: 'paymentPut',
		paymentExecutedPatch: 'paymentExecutedPatch',
		invoicePost: 'invoicePost',
		invoicePut: 'invoicePut',
    },

    chronology: {
		chronologyGet: 'chronologyGet',
		pageGet: 'pageGet',
		pageVersionGet: 'pageVersionGet',
		paymentGet: 'paymentGet',
		paymentCountGet: 'paymentCountGet',
		paymentRepeatGet: 'paymentRepeatGet',
		paymentPost: 'paymentPost',
		paymentHidePatch: 'paymentHidePatch',
		pfmGet: 'pfmGet',
		pfmCategoryGet: 'pfmCategoryGet',
		pfmGroupGet: 'pfmGroupGet',
		receiptGet: 'receiptGet',
    },

    claims: {
		clientIssuePost: 'clientIssuePost',
		clientIssueExecutePatch: 'clientIssueExecutePatch',
		clientPersonalDataAddressTypeGet: 'clientPersonalDataAddressTypeGet',
		clientPersonalDataAvailablePersonalRequisitesGet: 'clientPersonalDataAvailablePersonalRequisitesGet',
		clientPersonalDataIssuePost: 'clientPersonalDataIssuePost',
		clientPersonalDataIssueExecutePatch: 'clientPersonalDataIssueExecutePatch',
		clientPersonalDataResidenceTypeGet: 'clientPersonalDataResidenceTypeGet',
		issuePost: 'issuePost',
		issueExecutedPatch: 'issueExecutedPatch',
		operationContestPost: 'operationContestPost',
		operationContestCasesGet: 'operationContestCasesGet',
		operationContestExecutePatch: 'operationContestExecutePatch',
		paymentDetailsIssuePost: 'paymentDetailsIssuePost',
		paymentDetailsIssueExecutedPatch: 'paymentDetailsIssueExecutedPatch',
		personalStatementPatch: 'personalStatementPatch',
		statementGet: 'statementGet',
		statementPatch: 'statementPatch',
		statementAccountStatementPost: 'statementAccountStatementPost',
		statementAccountStatementFilePost: 'statementAccountStatementFilePost',
		statementAccountStatementFileGet: 'statementAccountStatementFileGet',
		statementAccountStatementGet: 'statementAccountStatementGet',
		statementAvailableStatementsGet: 'statementAvailableStatementsGet',
		statementParamsGet: 'statementParamsGet',
		statementPersonalAvailableStatementsGet: 'statementPersonalAvailableStatementsGet',
		typeGet: 'typeGet',
    },

    clientCabinet: {
		contentGet: 'contentGet',
		contentViewPut: 'contentViewPut',
		markersGet: 'markersGet',
    },

    closingAccount: {
		documentPost: 'documentPost',
		documentExecutePatch: 'documentExecutePatch',
		currencyCheckGet: 'currencyCheckGet',
		checkGet: 'checkGet',
    },

    communication: {
		communicationGet: 'communicationGet',
		addPost: 'addPost',
		addProfileInfoGet: 'addProfileInfoGet',
		deletePost: 'deletePost',
		editPost: 'editPost',
		editProfileInfoGet: 'editProfileInfoGet',
    },

    concierge: {
		issuePost: 'issuePost',
		emailPost: 'emailPost',
		contentGet: 'contentGet',
    },

    courierDelivery: {
		timeslotsGet: 'timeslotsGet',
		orderPost: 'orderPost',
		orderPut: 'orderPut',
		orderPatch: 'orderPatch',
		orderGet: 'orderGet',
		orderDetailsGet: 'orderDetailsGet',
		orderStatusPatch: 'orderStatusPatch',
		updatePost: 'updatePost',
		cardDeliveryCitiesPut: 'cardDeliveryCitiesPut',
		cardDeliveryCitiesPost: 'cardDeliveryCitiesPost',
		cardDeliveryCitiesGet: 'cardDeliveryCitiesGet',
		cardDeliveryCitiesDelete: 'cardDeliveryCitiesDelete',
		cardDeliveryCitiesPackagePost: 'cardDeliveryCitiesPackagePost',
    },

    creditcard: {
		installmentPut: 'installmentPut',
		installmentDelete: 'installmentDelete',
		installmentPost: 'installmentPost',
		deliveryPost: 'deliveryPost',
		installmentReducedPatch: 'installmentReducedPatch',
		installmentExecutedPatch: 'installmentExecutedPatch',
		installmentAgreementSignedGet: 'installmentAgreementSignedGet',
		installmentAgreementPreviewGet: 'installmentAgreementPreviewGet',
    },

    creditpay: {
		accountNumberPaymentTypeGet: 'accountNumberPaymentTypeGet',
		paymentExecutedPatch: 'paymentExecutedPatch',
		privatePaymentPost: 'privatePaymentPost',
		privatePaymentPartialUpdatePatch: 'privatePaymentPartialUpdatePatch',
		privatePaymentPut: 'privatePaymentPut',
		productPaymentTypeGet: 'productPaymentTypeGet',
		authorizationGet: 'authorizationGet',
		authorizationPost: 'authorizationPost',
		authorizationAuthorizedPatch: 'authorizationAuthorizedPatch',
		documentPost: 'documentPost',
		documentPartialUpdatePatch: 'documentPartialUpdatePatch',
		documentGet: 'documentGet',
		documentConfirmedPatch: 'documentConfirmedPatch',
		orderPost: 'orderPost',
		orderExecutedPatch: 'orderExecutedPatch',
    },

    currentAccount: {
		accountIssuePost: 'accountIssuePost',
		accountIssuePut: 'accountIssuePut',
		accountIssueExecutedPatch: 'accountIssueExecutedPatch',
		openAccountInfoGet: 'openAccountInfoGet',
    },

    customerinfo: {
		agreementGet: 'agreementGet',
		agreementApplyPost: 'agreementApplyPost',
		clientInfoGet: 'clientInfoGet',
		clientsAccountGet: 'clientsAccountGet',
		clientsCardGet: 'clientsCardGet',
		consentGet: 'consentGet',
		consentPost: 'consentPost',
		consentPathGet: 'consentPathGet',
		contactsPost: 'contactsPost',
		crmGet: 'crmGet',
		defaultbankGet: 'defaultbankGet',
		defaultbankStatusGet: 'defaultbankStatusGet',
		defaultbankPatch: 'defaultbankPatch',
		ipssubscriptionsSettingsGet: 'ipssubscriptionsSettingsGet',
		ipssubscriptionsSettingsPut: 'ipssubscriptionsSettingsPut',
		ipssubscriptionsSettingsDelete: 'ipssubscriptionsSettingsDelete',
		managerGet: 'managerGet',
		me2meSettingsGet: 'me2meSettingsGet',
		me2meSettingsPut: 'me2meSettingsPut',
		me2meSettingsPost: 'me2meSettingsPost',
		me2meSettingsDelete: 'me2meSettingsDelete',
		me2meSettingsPatch: 'me2meSettingsPatch',
		me2meSettingsAllDelete: 'me2meSettingsAllDelete',
		productSettingsPut: 'productSettingsPut',
		productSettingsMainScreenPut: 'productSettingsMainScreenPut',
		profileGet: 'profileGet',
		profileDetailsGet: 'profileDetailsGet',
		quickactionsGet: 'quickactionsGet',
		sbpayDependenceGet: 'sbpayDependenceGet',
		sbpaySettingsGet: 'sbpaySettingsGet',
		sbpaySettingsPut: 'sbpaySettingsPut',
		sbpaySettingsPost: 'sbpaySettingsPost',
		sbpaySettingsDelete: 'sbpaySettingsDelete',
		segmentGet: 'segmentGet',
		settingsGet: 'settingsGet',
		settingsPost: 'settingsPost',
		settingsDelete: 'settingsDelete',
		settingsPatch: 'settingsPatch',
		softtokenActivatePost: 'softtokenActivatePost',
		softtokenDeactivatePost: 'softtokenDeactivatePost',
		softtokenGeneratePost: 'softtokenGeneratePost',
		softtokenStatusGet: 'softtokenStatusGet',
		userLoginPatch: 'userLoginPatch',
		userGet: 'userGet',
		clientsCardFullResponseGet: 'clientsCardFullResponseGet',
		consentsGet: 'consentsGet',
		consentsPost: 'consentsPost',
		deviceGet: 'deviceGet',
		deviceChangePatch: 'deviceChangePatch',
		deviceDeactivatePatch: 'deviceDeactivatePatch',
		extendedUserPatch: 'extendedUserPatch',
		extendedUserInfoGet: 'extendedUserInfoGet',
		legalAccountGet: 'legalAccountGet',
		legalInfoGet: 'legalInfoGet',
    },

    deeplink: {
		longGet: 'longGet',
		universalGet: 'universalGet',
    },

    demandpay: {
		insuranceCompanyGet: 'insuranceCompanyGet',
		insuranceRequisitesPost: 'insuranceRequisitesPost',
		legalPaymentPost: 'legalPaymentPost',
		legalPaymentPut: 'legalPaymentPut',
		paymentExecutedPatch: 'paymentExecutedPatch',
    },

    dialogs: {
		chatMessagesPut: 'chatMessagesPut',
		omnichatTokenPost: 'omnichatTokenPost',
		omnichatActionPost: 'omnichatActionPost',
		chatMessagePost: 'chatMessagePost',
		omnichatSettingGet: 'omnichatSettingGet',
		chatGet: 'chatGet',
		chatDetailsGet: 'chatDetailsGet',
		chatDelete: 'chatDelete',
    },

    fileUpload: {
		fileUploadGet: 'fileUploadGet',
		fileUploadPost: 'fileUploadPost',
    },

    finance: {
		carloanLinkPost: 'carloanLinkPost',
		financePatch: 'financePatch',
		mortgageGet: 'mortgageGet',
		mortgagePatch: 'mortgagePatch',
		carloanGet: 'carloanGet',
		carloanPatch: 'carloanPatch',
		financeGet: 'financeGet',
		restructuringLinkGet: 'restructuringLinkGet',
		requisitesGet: 'requisitesGet',
		detailsGet: 'detailsGet',
		actionsGet: 'actionsGet',
		savingsGet: 'savingsGet',
		savingsDetailsGet: 'savingsDetailsGet',
		mortgageLinkGet: 'mortgageLinkGet',
		investRedemptionSumsGet: 'investRedemptionSumsGet',
		investPaymentScheduleGet: 'investPaymentScheduleGet',
		investDetailsGet: 'investDetailsGet',
		insuranceGet: 'insuranceGet',
		insuranceHintGet: 'insuranceHintGet',
		depositGet: 'depositGet',
		currentGet: 'currentGet',
		currentDetailsGet: 'currentDetailsGet',
		creditGet: 'creditGet',
		creditScheduleGet: 'creditScheduleGet',
		carloanLinkGet: 'carloanLinkGet',
		accountGet: 'accountGet',
    },

    instantpay: {
		personPhoneGet: 'personPhoneGet',
		personPhonePut: 'personPhonePut',
		personPhonePatch: 'personPhonePatch',
		inputDocumentPut: 'inputDocumentPut',
		personPhonePost: 'personPhonePost',
		personPhoneTechnicalPost: 'personPhoneTechnicalPost',
		personPhoneByTemplatePost: 'personPhoneByTemplatePost',
		outputCheckPost: 'outputCheckPost',
		inputDocumentPost: 'inputDocumentPost',
		directLinkPost: 'directLinkPost',
		personPhoneSettledPatch: 'personPhoneSettledPatch',
		personPhoneExecutedPatch: 'personPhoneExecutedPatch',
		outputDocumentUpdatePatch: 'outputDocumentUpdatePatch',
		outputDocumentExecutePatch: 'outputDocumentExecutePatch',
		outputDocumentCancelPatch: 'outputDocumentCancelPatch',
		inputDocumentConfirmPayPatch: 'inputDocumentConfirmPayPatch',
		phoneinfoPayeeGet: 'phoneinfoPayeeGet',
		outputDocumentGet: 'outputDocumentGet',
		memberMe2meGet: 'memberMe2meGet',
		freeLimitGet: 'freeLimitGet',
		banklistGet: 'banklistGet',
		countryCodeGet: 'countryCodeGet',
    },

    legalpay: {
		paymentExecutedPatch: 'paymentExecutedPatch',
		paymentFeecalculatePut: 'paymentFeecalculatePut',
		paymentSettledPatch: 'paymentSettledPatch',
		sbpC2bAwPost: 'sbpC2bAwPost',
		acknowledgementPatch: 'acknowledgementPatch',
		petitionPost: 'petitionPost',
    },

    limit: {
		customerGet: 'customerGet',
		customerSingleLimitGet: 'customerSingleLimitGet',
    },

    localization: {
		localization_channel_Post: 'localization_channel_Post',
		localization_channel_Key_key_Get: 'localization_channel_Key_key_Get',
		localizationVersion_version_Get: 'localizationVersion_version_Get',
    },

    loyalty: {
		loyaltyGet: 'loyaltyGet',
		bannersGet: 'bannersGet',
		bonushistoryGet: 'bonushistoryGet',
		bonushistoryPageGet: 'bonushistoryPageGet',
		cardGet: 'cardGet',
		cardCategoryGet: 'cardCategoryGet',
		cardPost: 'cardPost',
		categoriesGet: 'categoriesGet',
		categoryOffersGet: 'categoryOffersGet',
		memberProgramGet: 'memberProgramGet',
		memberProgramBalanceInfoGet: 'memberProgramBalanceInfoGet',
		offersGet: 'offersGet',
		offersFavouriteGet: 'offersFavouriteGet',
		offersDetailsGet: 'offersDetailsGet',
		offersFavouritePut: 'offersFavouritePut',
		offersFavouritePost: 'offersFavouritePost',
		operationsGet: 'operationsGet',
		travelurlGet: 'travelurlGet',
		actionGet: 'actionGet',
		categoryGet: 'categoryGet',
		categoryPatch: 'categoryPatch',
		deactivatePatch: 'deactivatePatch',
		graphGet: 'graphGet',
		ruleGet: 'ruleGet',
		switchPatch: 'switchPatch',
		programFAQGet: 'programFAQGet',
    },

    mortgage: {
		cardOrderPost: 'cardOrderPost',
		mortgageGet: 'mortgageGet',
		mortgagePatch: 'mortgagePatch',
		linkGet: 'linkGet',
		accountRequisitesGet: 'accountRequisitesGet',
		paymentsGet: 'paymentsGet',
    },

    notification: {
		notificationGet: 'notificationGet',
		deletedPut: 'deletedPut',
		documentListGet: 'documentListGet',
		informedPut: 'informedPut',
		messageDeletedPut: 'messageDeletedPut',
		messageHistoryGet: 'messageHistoryGet',
		messageHistoryReadPatch: 'messageHistoryReadPatch',
		readPut: 'readPut',
		splashAllGet: 'splashAllGet',
		splashStyleGet: 'splashStyleGet',
		splashBackwardedPatch: 'splashBackwardedPatch',
		splashClosedPatch: 'splashClosedPatch',
		splashInformedPatch: 'splashInformedPatch',
		splashReadPatch: 'splashReadPatch',
		technicalGet: 'technicalGet',
		technicalCountPut: 'technicalCountPut',
    },

    operationTemplate: {
		templateGet: 'templateGet',
		templatePut: 'templatePut',
		templateDelete: 'templateDelete',
		templatePatch: 'templatePatch',
		templatePost: 'templatePost',
		statementPost: 'statementPost',
		statementCancelledPost: 'statementCancelledPost',
		regularpaymentorderSuspendPatch: 'regularpaymentorderSuspendPatch',
		vendorGet: 'vendorGet',
		vendorDetailsGet: 'vendorDetailsGet',
		logoGet: 'logoGet',
    },

    paperlessOffice: {
		documentPost: 'documentPost',
		documentGet: 'documentGet',
		issuePost: 'issuePost',
		issueExecutePatch: 'issueExecutePatch',
		relatedProductsGet: 'relatedProductsGet',
		relatedProductsConfirmPatch: 'relatedProductsConfirmPatch',
    },

    productCatalog: {
		contentsGet: 'contentsGet',
		digitalCardOrderAvailableGet: 'digitalCardOrderAvailableGet',
		feedGet: 'feedGet',
		propositionContentGet: 'propositionContentGet',
		propositionStyleGet: 'propositionStyleGet',
		styleGet: 'styleGet',
    },

    push: {
		devicePost: 'devicePost',
		messageStatusPost: 'messageStatusPost',
		mfmDevicePatch: 'mfmDevicePatch',
		mfmDeviceGet: 'mfmDeviceGet',
    },

    qrrecognition: {
		recognitionPost: 'recognitionPost',
    },

    registration: {
		registrationPost: 'registrationPost',
		webbyConfirmPost: 'webbyConfirmPost',
		webbyOfferPost: 'webbyOfferPost',
		webbyOfferAgreementPost: 'webbyOfferAgreementPost',
		webbyOfferGet: 'webbyOfferGet',
    },

    repayment: {
		repaymentGet: 'repaymentGet',
		repaymentPut: 'repaymentPut',
		repaymentPost: 'repaymentPost',
		executedPatch: 'executedPatch',
    },

    requisitePay: {
		privatePaymentGet: 'privatePaymentGet',
		privatePaymentPut: 'privatePaymentPut',
		privatePaymentPatch: 'privatePaymentPatch',
		privatePaymentPost: 'privatePaymentPost',
		legalPaymentGet: 'legalPaymentGet',
		legalPaymentPut: 'legalPaymentPut',
		legalPaymentPatch: 'legalPaymentPatch',
		legalPaymentPost: 'legalPaymentPost',
		budgetPaymentPost: 'budgetPaymentPost',
		budgetPaymentGet: 'budgetPaymentGet',
		budgetPaymentPut: 'budgetPaymentPut',
		budgetPaymentPatch: 'budgetPaymentPatch',
		paymentExecutedPatch: 'paymentExecutedPatch',
		dictionaryBicUniversalGet: 'dictionaryBicUniversalGet',
		clientInnGet: 'clientInnGet',
		dictionaryBudgetGet: 'dictionaryBudgetGet',
		clientDetailsGet: 'clientDetailsGet',
		dictionaryPayee_typeGet: 'dictionaryPayee_typeGet',
		budgetPaymentByTemplatePost: 'budgetPaymentByTemplatePost',
		legalPaymentByTemplatePost: 'legalPaymentByTemplatePost',
		privatePaymentByTemplatePost: 'privatePaymentByTemplatePost',
		contractPaymentPut: 'contractPaymentPut',
		contractPaymentPost: 'contractPaymentPost',
		contractPaymentByTemplatePost: 'contractPaymentByTemplatePost',
		privatePaymentPartialUpdatePatch: 'privatePaymentPartialUpdatePatch',
		legalPaymentPartialUpdatePatch: 'legalPaymentPartialUpdatePatch',
		contractPaymentExecutedPatch: 'contractPaymentExecutedPatch',
		budgetPaymentPartialUpdatePatch: 'budgetPaymentPartialUpdatePatch',
		dictionaryCompanyGet: 'dictionaryCompanyGet',
		dictionaryBicGet: 'dictionaryBicGet',
		dictionaryBicRuledGet: 'dictionaryBicRuledGet',
    },

    sale: {
		authForwardGet: 'authForwardGet',
		authForwardPost: 'authForwardPost',
		authP2uGet: 'authP2uGet',
		cardUpdateGet: 'cardUpdateGet',
		staticStyleGet: 'staticStyleGet',
		staticStyleHead: 'staticStyleHead',
		staticStylePost: 'staticStylePost',
		staticStylePut: 'staticStylePut',
		staticStyleDelete: 'staticStyleDelete',
		staticStyleOptions: 'staticStyleOptions',
		staticStylePatch: 'staticStylePatch',
    },

    saving: {
		accountIssuePost: 'accountIssuePost',
		accountIssuePut: 'accountIssuePut',
		accountIssueExecutedPatch: 'accountIssueExecutedPatch',
		accountBannerGet: 'accountBannerGet',
		bonusAccountsGet: 'bonusAccountsGet',
		bonusConditionGet: 'bonusConditionGet',
		bonusDescriptionGet: 'bonusDescriptionGet',
		bonusMccGet: 'bonusMccGet',
		bonusDetailsGet: 'bonusDetailsGet',
		bonusHistoryGet: 'bonusHistoryGet',
		bonusOrderPost: 'bonusOrderPost',
		depositCalculationGet: 'depositCalculationGet',
		depositCloseDepositIssueExecutedPatch: 'depositCloseDepositIssueExecutedPatch',
		depositDocumentPut: 'depositDocumentPut',
		depositDocumentConditionsGet: 'depositDocumentConditionsGet',
		depositIssuePost: 'depositIssuePost',
		depositIssuePut: 'depositIssuePut',
		depositIssueExecutedPatch: 'depositIssueExecutedPatch',
		depositIssueFormGet: 'depositIssueFormGet',
		depositIssuePreconditionGet: 'depositIssuePreconditionGet',
		depositIssueSearchGet: 'depositIssueSearchGet',
		depositIssueThresholdGet: 'depositIssueThresholdGet',
		depositOrderPost: 'depositOrderPost',
		depositProfitGet: 'depositProfitGet',
		depositWithdrawIssueExecutedPatch: 'depositWithdrawIssueExecutedPatch',
		depositCloseDepositIssuePost: 'depositCloseDepositIssuePost',
		depositWithdrawIssuePost: 'depositWithdrawIssuePost',
		depositConditionsPdfPreparationGet: 'depositConditionsPdfPreparationGet',
		depositConditionsPdfGet: 'depositConditionsPdfGet',
		openAccountInfoGet: 'openAccountInfoGet',
    },

    servicePay: {
		directInvoicePost: 'directInvoicePost',
		directInvoicePut: 'directInvoicePut',
		directPaymentPost: 'directPaymentPost',
		directPaymentGet: 'directPaymentGet',
		directPaymentPut: 'directPaymentPut',
		directPaymentExecutedPatch: 'directPaymentExecutedPatch',
		invoicePost: 'invoicePost',
		invoicePut: 'invoicePut',
		paymentPost: 'paymentPost',
		paymentGet: 'paymentGet',
		paymentPut: 'paymentPut',
		paymentExecutedPatch: 'paymentExecutedPatch',
		paymentRepeatGet: 'paymentRepeatGet',
    },

    servicePoint: {
		listPost: 'listPost',
		mapPost: 'mapPost',
		officeDetailsGet: 'officeDetailsGet',
		officeTimeTableGet: 'officeTimeTableGet',
    },

    statement: {
		personalPost: 'personalPost',
		accountStatementPost: 'accountStatementPost',
		accountStatementFilePost: 'accountStatementFilePost',
		statementGet: 'statementGet',
		personalAvailableStatementsGet: 'personalAvailableStatementsGet',
		cardStatementGet: 'cardStatementGet',
		accountStatementGet: 'accountStatementGet',
		accountStatementFileGet: 'accountStatementFileGet',
    },

    tariff: {
		priorityGet: 'priorityGet',
		priorityPassesGet: 'priorityPassesGet',
		tariffGet: 'tariffGet',
		tariffReceiptGet: 'tariffReceiptGet',
    },

    vendor: {
		categoryGet: 'categoryGet',
		categoryProductGet: 'categoryProductGet',
		productGet: 'productGet',
		productSettingsGet: 'productSettingsGet',
    },
};


/**
* @description MethodToServiceMap.
*/
export const MethodToServiceMap = {
	[AccountPayMethods.accountAccountNumberGet]: Services.accountPay,
	[AccountPayMethods.accountCardNumberGet]: Services.accountPay,
	[AccountPayMethods.accountPaymentGet]: Services.accountPay,
	[AccountPayMethods.accountPhoneGet]: Services.accountPay,
	[AccountPayMethods.clientAccountPost]: Services.accountPay,
	[AccountPayMethods.clientAccountByTemplatePost]: Services.accountPay,
	[AccountPayMethods.clientAccountGet]: Services.accountPay,
	[AccountPayMethods.clientAccountPut]: Services.accountPay,
	[AccountPayMethods.clientAccountPatch]: Services.accountPay,
	[AccountPayMethods.clientAccountExecutedPatch]: Services.accountPay,
	[AccountPayMethods.clientPartialUpdatePatch]: Services.accountPay,
	[AccountPayMethods.depositPost]: Services.accountPay,
	[AccountPayMethods.depositMinimumContributionGet]: Services.accountPay,
	[AccountPayMethods.depositPut]: Services.accountPay,
	[AccountPayMethods.depositExecutedPatch]: Services.accountPay,
	[AccountPayMethods.exchangePost]: Services.accountPay,
	[AccountPayMethods.exchangeMainCurrencyGet]: Services.accountPay,
	[AccountPayMethods.exchangeGet]: Services.accountPay,
	[AccountPayMethods.exchangePut]: Services.accountPay,
	[AccountPayMethods.exchangePatch]: Services.accountPay,
	[AccountPayMethods.exchangeExecutedPatch]: Services.accountPay,
	[AccountPayMethods.ownPost]: Services.accountPay,
	[AccountPayMethods.ownByTemplatePost]: Services.accountPay,
	[AccountPayMethods.ownPartialUpdatePatch]: Services.accountPay,
	[AccountPayMethods.ownGet]: Services.accountPay,
	[AccountPayMethods.ownPut]: Services.accountPay,
	[AccountPayMethods.ownPatch]: Services.accountPay,
	[AccountPayMethods.ownExecutedPatch]: Services.accountPay,
	[AccountPayMethods.exchangedealGet]: Services.accountPay,
	[AccountPayMethods.exchangedealPut]: Services.accountPay,
	[AccountPayMethods.exchangedealPost]: Services.accountPay,
	[AccountPayMethods.exchangedealListPost]: Services.accountPay,
	[AccountPayMethods.exchangedealExecutePatch]: Services.accountPay,
	[ActionsMethods.actionsGet]: Services.actions,
	[AuditMethods.eventPost]: Services.audit,
	[AuditMethods.reviewPost]: Services.audit,
	[AuditMethods.runtimePost]: Services.audit,
	[AuthorizationMethods.oauthAuthorizePost]: Services.authorization,
	[AuthorizationMethods.oauthInfoGet]: Services.authorization,
	[AuthorizationMethods.oauthCheck_tokenPost]: Services.authorization,
	[AuthorizationMethods.oauthLoginPost]: Services.authorization,
	[AuthorizationMethods.oauthLogoutPatch]: Services.authorization,
	[AuthorizationMethods.oauthTokenPost]: Services.authorization,
	[AuthorizationMethods.oauthPinPost]: Services.authorization,
	[AuthorizationMethods.credentialInitialPost]: Services.authorization,
	[AuthorizationMethods.credentialRegisterPost]: Services.authorization,
	[AuthorizationMethods.credentialChallengePost]: Services.authorization,
	[AutocarMethods.autotekaReportVehiclePost]: Services.autocar,
	[AutocarMethods.autotekaReportVehicleGet]: Services.autocar,
	[AutocarMethods.carLoanInfoGet]: Services.autocar,
	[AutocarMethods.dictionaryAutoBrandGet]: Services.autocar,
	[AutocarMethods.dictionaryAutoModelGet]: Services.autocar,
	[AutocarMethods.dictionaryAutoModificationGet]: Services.autocar,
	[AutocarMethods.dictionaryAutocardInfoGet]: Services.autocar,
	[AutocarMethods.dictionaryBankGet]: Services.autocar,
	[AutocarMethods.dictionaryCredentialTypeGet]: Services.autocar,
	[AutocarMethods.dictionaryRegionGet]: Services.autocar,
	[AutocarMethods.dictionaryVehicleInfoGet]: Services.autocar,
	[AutocarMethods.fileAgreementGet]: Services.autocar,
	[AutocarMethods.insuranceInvoiceOverduePenaltyPost]: Services.autocar,
	[AutocarMethods.issueGet]: Services.autocar,
	[AutocarMethods.issuePost]: Services.autocar,
	[AutocarMethods.issueAutoPatch]: Services.autocar,
	[AutocarMethods.issueCalculatePatch]: Services.autocar,
	[AutocarMethods.issueConfirmPatch]: Services.autocar,
	[AutocarMethods.issueDriversPatch]: Services.autocar,
	[AutocarMethods.issueImportPatch]: Services.autocar,
	[AutocarMethods.issuePersonalDataPatch]: Services.autocar,
	[AutocarMethods.paymentPost]: Services.autocar,
	[AutocarMethods.paymentPut]: Services.autocar,
	[AutocarMethods.paymentExecutedPatch]: Services.autocar,
	[BillboardMethods.advertisingGet]: Services.billboard,
	[BillboardMethods.advertisingDelete]: Services.billboard,
	[BillboardMethods.advertisingCountPut]: Services.billboard,
	[BillboardMethods.advertisingTapPut]: Services.billboard,
	[BillboardMethods.privilegeContentGet]: Services.billboard,
	[BillboardMethods.productcatalogContentGet]: Services.billboard,
	[BillboardMethods.productcatalogStyleGet]: Services.billboard,
	[BillboardMethods.recommendedProductGet]: Services.billboard,
	[BillboardMethods.recommendedProductInvitationsGet]: Services.billboard,
	[BillboardMethods.sliderContentGet]: Services.billboard,
	[BillboardMethods.styleGet]: Services.billboard,
	[BillboardMethods.tetrisGet]: Services.billboard,
	[CallbackMethods.callbackPost]: Services.callback,
	[CardMethods.cardGet]: Services.card,
	[CardMethods.aliasGet]: Services.card,
	[CardMethods.aliasPut]: Services.card,
	[CardMethods.aliasDelete]: Services.card,
	[CardMethods.digitalAvailableGet]: Services.card,
	[CardMethods.digitalCancelPatch]: Services.card,
	[CardMethods.digitalIssuePost]: Services.card,
	[CardMethods.digitalIssuePut]: Services.card,
	[CardMethods.cardPut]: Services.card,
	[CardMethods.activatePost]: Services.card,
	[CardMethods.activateCanceledPatch]: Services.card,
	[CardMethods.activateExecutedPatch]: Services.card,
	[CardMethods.changeAccountPatch]: Services.card,
	[CardMethods.changepinPost]: Services.card,
	[CardMethods.changepinCanceledPatch]: Services.card,
	[CardMethods.changepinExecutedPatch]: Services.card,
	[CardMethods.limitGet]: Services.card,
	[CardMethods.limitPatch]: Services.card,
	[CardMethods.operationLimitGet]: Services.card,
	[CardMethods.requisitesPost]: Services.card,
	[CardMethods.statusPatch]: Services.card,
	[CardMethods.detailGet]: Services.card,
	[CardMethods.statusPost]: Services.card,
	[CardMethods.productUidDetailsGet]: Services.card,
	[CardMethods.corporateGet]: Services.card,
	[CardIssueMethods.linkIssuePut]: Services.cardIssue,
	[CardIssueMethods.digitalIssuePut]: Services.cardIssue,
	[CardIssueMethods.processStageChangedPost]: Services.cardIssue,
	[CardIssueMethods.linkIssuePost]: Services.cardIssue,
	[CardIssueMethods.digitalIssuePost]: Services.cardIssue,
	[CardIssueMethods.processStageChangedExecutedPatch]: Services.cardIssue,
	[CardIssueMethods.processStageChangedCancelledPatch]: Services.cardIssue,
	[CardIssueMethods.linkIssueExecutedPatch]: Services.cardIssue,
	[CardIssueMethods.linkIssueCancelledPatch]: Services.cardIssue,
	[CardIssueMethods.digitalCancelPatch]: Services.cardIssue,
	[CardIssueMethods.digitalAvailableGet]: Services.cardIssue,
	[CardIssueMethods.cardOrderGet]: Services.cardIssue,
	[CardIssueMethods.cardOrderOrderGet]: Services.cardIssue,
	[CardIssueMethods.cardOrderDeliveryCardsGet]: Services.cardIssue,
	[CardPayMethods.paymentGet]: Services.cardPay,
	[CardPayMethods.paymentPut]: Services.cardPay,
	[CardPayMethods.paymentPatch]: Services.cardPay,
	[CardPayMethods.twpgPost]: Services.cardPay,
	[CardPayMethods.paymentPost]: Services.cardPay,
	[CardPayMethods.paymentTwpgPost]: Services.cardPay,
	[CardPayMethods.paymentByTemplatePost]: Services.cardPay,
	[CardPayMethods.paymentExecutedPatch]: Services.cardPay,
	[CardPayMethods.paymentCresPatch]: Services.cardPay,
	[CardPayMethods.paymentAreqPatch]: Services.cardPay,
	[CardPayMethods.paymentPartialUpdatePatch]: Services.cardPay,
	[CardPayMethods.paymentStatusGet]: Services.cardPay,
	[CardPayMethods.checkCountryGet]: Services.cardPay,
	[CardPayMethods.cardInfoGet]: Services.cardPay,
	[CardPayMethods.cardInfoClientGet]: Services.cardPay,
	[CardPayMethods.aliasInfoClientGet]: Services.cardPay,
	[CarloanMethods.cardOrderPost]: Services.carloan,
	[CarloanMethods.finServiceRequestPost]: Services.carloan,
	[CarloanMethods.notesGet]: Services.carloan,
	[CarloanMethods.periodProductActionShowGet]: Services.carloan,
	[ChargeMethods.chargeGet]: Services.charge,
	[ChargeMethods.clientGet]: Services.charge,
	[ChargeMethods.countGet]: Services.charge,
	[ChargeMethods.currentUpcomingExpensesGet]: Services.charge,
	[ChargeMethods.gisgmpPost]: Services.charge,
	[ChargeMethods.giszkhPost]: Services.charge,
	[ChargeMethods.mortgagePaymentsGet]: Services.charge,
	[ChargeMethods.subscriptionGet]: Services.charge,
	[ChargeMethods.subscriptionPost]: Services.charge,
	[ChargeMethods.subscriptionPut]: Services.charge,
	[ChargeMethods.subscriptionDelete]: Services.charge,
	[ChargeMethods.vendorsGet]: Services.charge,
	[ChargeMethods.chargePatch]: Services.charge,
	[ChargepayMethods.paymentPost]: Services.chargepay,
	[ChargepayMethods.paymentGet]: Services.chargepay,
	[ChargepayMethods.paymentPut]: Services.chargepay,
	[ChargepayMethods.paymentExecutedPatch]: Services.chargepay,
	[ChargepayMethods.invoicePost]: Services.chargepay,
	[ChargepayMethods.invoicePut]: Services.chargepay,
	[ChronologyMethods.chronologyGet]: Services.chronology,
	[ChronologyMethods.pageGet]: Services.chronology,
	[ChronologyMethods.pageVersionGet]: Services.chronology,
	[ChronologyMethods.paymentGet]: Services.chronology,
	[ChronologyMethods.paymentCountGet]: Services.chronology,
	[ChronologyMethods.paymentRepeatGet]: Services.chronology,
	[ChronologyMethods.paymentPost]: Services.chronology,
	[ChronologyMethods.paymentHidePatch]: Services.chronology,
	[ChronologyMethods.pfmGet]: Services.chronology,
	[ChronologyMethods.pfmCategoryGet]: Services.chronology,
	[ChronologyMethods.pfmGroupGet]: Services.chronology,
	[ChronologyMethods.receiptGet]: Services.chronology,
	[ClaimsMethods.clientIssuePost]: Services.claims,
	[ClaimsMethods.clientIssueExecutePatch]: Services.claims,
	[ClaimsMethods.clientPersonalDataAddressTypeGet]: Services.claims,
	[ClaimsMethods.clientPersonalDataAvailablePersonalRequisitesGet]: Services.claims,
	[ClaimsMethods.clientPersonalDataIssuePost]: Services.claims,
	[ClaimsMethods.clientPersonalDataIssueExecutePatch]: Services.claims,
	[ClaimsMethods.clientPersonalDataResidenceTypeGet]: Services.claims,
	[ClaimsMethods.issuePost]: Services.claims,
	[ClaimsMethods.issueExecutedPatch]: Services.claims,
	[ClaimsMethods.operationContestPost]: Services.claims,
	[ClaimsMethods.operationContestCasesGet]: Services.claims,
	[ClaimsMethods.operationContestExecutePatch]: Services.claims,
	[ClaimsMethods.paymentDetailsIssuePost]: Services.claims,
	[ClaimsMethods.paymentDetailsIssueExecutedPatch]: Services.claims,
	[ClaimsMethods.personalStatementPatch]: Services.claims,
	[ClaimsMethods.statementGet]: Services.claims,
	[ClaimsMethods.statementPatch]: Services.claims,
	[ClaimsMethods.statementAccountStatementPost]: Services.claims,
	[ClaimsMethods.statementAccountStatementFilePost]: Services.claims,
	[ClaimsMethods.statementAccountStatementFileGet]: Services.claims,
	[ClaimsMethods.statementAccountStatementGet]: Services.claims,
	[ClaimsMethods.statementAvailableStatementsGet]: Services.claims,
	[ClaimsMethods.statementParamsGet]: Services.claims,
	[ClaimsMethods.statementPersonalAvailableStatementsGet]: Services.claims,
	[ClaimsMethods.typeGet]: Services.claims,
	[ClientCabinetMethods.contentGet]: Services.clientCabinet,
	[ClientCabinetMethods.contentViewPut]: Services.clientCabinet,
	[ClientCabinetMethods.markersGet]: Services.clientCabinet,
	[ClosingAccountMethods.documentPost]: Services.closingAccount,
	[ClosingAccountMethods.documentExecutePatch]: Services.closingAccount,
	[ClosingAccountMethods.currencyCheckGet]: Services.closingAccount,
	[ClosingAccountMethods.checkGet]: Services.closingAccount,
	[CommunicationMethods.communicationGet]: Services.communication,
	[CommunicationMethods.addPost]: Services.communication,
	[CommunicationMethods.addProfileInfoGet]: Services.communication,
	[CommunicationMethods.deletePost]: Services.communication,
	[CommunicationMethods.editPost]: Services.communication,
	[CommunicationMethods.editProfileInfoGet]: Services.communication,
	[ConciergeMethods.issuePost]: Services.concierge,
	[ConciergeMethods.emailPost]: Services.concierge,
	[ConciergeMethods.contentGet]: Services.concierge,
	[CourierDeliveryMethods.timeslotsGet]: Services.courierDelivery,
	[CourierDeliveryMethods.orderPost]: Services.courierDelivery,
	[CourierDeliveryMethods.orderPut]: Services.courierDelivery,
	[CourierDeliveryMethods.orderPatch]: Services.courierDelivery,
	[CourierDeliveryMethods.orderGet]: Services.courierDelivery,
	[CourierDeliveryMethods.orderDetailsGet]: Services.courierDelivery,
	[CourierDeliveryMethods.orderStatusPatch]: Services.courierDelivery,
	[CourierDeliveryMethods.updatePost]: Services.courierDelivery,
	[CourierDeliveryMethods.cardDeliveryCitiesPut]: Services.courierDelivery,
	[CourierDeliveryMethods.cardDeliveryCitiesPost]: Services.courierDelivery,
	[CourierDeliveryMethods.cardDeliveryCitiesGet]: Services.courierDelivery,
	[CourierDeliveryMethods.cardDeliveryCitiesDelete]: Services.courierDelivery,
	[CourierDeliveryMethods.cardDeliveryCitiesPackagePost]: Services.courierDelivery,
	[CreditcardMethods.installmentPut]: Services.creditcard,
	[CreditcardMethods.installmentDelete]: Services.creditcard,
	[CreditcardMethods.installmentPost]: Services.creditcard,
	[CreditcardMethods.deliveryPost]: Services.creditcard,
	[CreditcardMethods.installmentReducedPatch]: Services.creditcard,
	[CreditcardMethods.installmentExecutedPatch]: Services.creditcard,
	[CreditcardMethods.installmentAgreementSignedGet]: Services.creditcard,
	[CreditcardMethods.installmentAgreementPreviewGet]: Services.creditcard,
	[CreditpayMethods.accountNumberPaymentTypeGet]: Services.creditpay,
	[CreditpayMethods.paymentExecutedPatch]: Services.creditpay,
	[CreditpayMethods.privatePaymentPost]: Services.creditpay,
	[CreditpayMethods.privatePaymentPartialUpdatePatch]: Services.creditpay,
	[CreditpayMethods.privatePaymentPut]: Services.creditpay,
	[CreditpayMethods.productPaymentTypeGet]: Services.creditpay,
	[CreditpayMethods.authorizationGet]: Services.creditpay,
	[CreditpayMethods.authorizationPost]: Services.creditpay,
	[CreditpayMethods.authorizationAuthorizedPatch]: Services.creditpay,
	[CreditpayMethods.documentPost]: Services.creditpay,
	[CreditpayMethods.documentPartialUpdatePatch]: Services.creditpay,
	[CreditpayMethods.documentGet]: Services.creditpay,
	[CreditpayMethods.documentConfirmedPatch]: Services.creditpay,
	[CreditpayMethods.orderPost]: Services.creditpay,
	[CreditpayMethods.orderExecutedPatch]: Services.creditpay,
	[CurrentAccountMethods.accountIssuePost]: Services.currentAccount,
	[CurrentAccountMethods.accountIssuePut]: Services.currentAccount,
	[CurrentAccountMethods.accountIssueExecutedPatch]: Services.currentAccount,
	[CurrentAccountMethods.openAccountInfoGet]: Services.currentAccount,
	[CustomerinfoMethods.agreementGet]: Services.customerinfo,
	[CustomerinfoMethods.agreementApplyPost]: Services.customerinfo,
	[CustomerinfoMethods.clientInfoGet]: Services.customerinfo,
	[CustomerinfoMethods.clientsAccountGet]: Services.customerinfo,
	[CustomerinfoMethods.clientsCardGet]: Services.customerinfo,
	[CustomerinfoMethods.consentGet]: Services.customerinfo,
	[CustomerinfoMethods.consentPost]: Services.customerinfo,
	[CustomerinfoMethods.consentPathGet]: Services.customerinfo,
	[CustomerinfoMethods.contactsPost]: Services.customerinfo,
	[CustomerinfoMethods.crmGet]: Services.customerinfo,
	[CustomerinfoMethods.defaultbankGet]: Services.customerinfo,
	[CustomerinfoMethods.defaultbankStatusGet]: Services.customerinfo,
	[CustomerinfoMethods.defaultbankPatch]: Services.customerinfo,
	[CustomerinfoMethods.ipssubscriptionsSettingsGet]: Services.customerinfo,
	[CustomerinfoMethods.ipssubscriptionsSettingsPut]: Services.customerinfo,
	[CustomerinfoMethods.ipssubscriptionsSettingsDelete]: Services.customerinfo,
	[CustomerinfoMethods.managerGet]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsGet]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsPut]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsPost]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsDelete]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsPatch]: Services.customerinfo,
	[CustomerinfoMethods.me2meSettingsAllDelete]: Services.customerinfo,
	[CustomerinfoMethods.productSettingsPut]: Services.customerinfo,
	[CustomerinfoMethods.productSettingsMainScreenPut]: Services.customerinfo,
	[CustomerinfoMethods.profileGet]: Services.customerinfo,
	[CustomerinfoMethods.profileDetailsGet]: Services.customerinfo,
	[CustomerinfoMethods.quickactionsGet]: Services.customerinfo,
	[CustomerinfoMethods.sbpayDependenceGet]: Services.customerinfo,
	[CustomerinfoMethods.sbpaySettingsGet]: Services.customerinfo,
	[CustomerinfoMethods.sbpaySettingsPut]: Services.customerinfo,
	[CustomerinfoMethods.sbpaySettingsPost]: Services.customerinfo,
	[CustomerinfoMethods.sbpaySettingsDelete]: Services.customerinfo,
	[CustomerinfoMethods.segmentGet]: Services.customerinfo,
	[CustomerinfoMethods.settingsGet]: Services.customerinfo,
	[CustomerinfoMethods.settingsPost]: Services.customerinfo,
	[CustomerinfoMethods.settingsDelete]: Services.customerinfo,
	[CustomerinfoMethods.settingsPatch]: Services.customerinfo,
	[CustomerinfoMethods.softtokenActivatePost]: Services.customerinfo,
	[CustomerinfoMethods.softtokenDeactivatePost]: Services.customerinfo,
	[CustomerinfoMethods.softtokenGeneratePost]: Services.customerinfo,
	[CustomerinfoMethods.softtokenStatusGet]: Services.customerinfo,
	[CustomerinfoMethods.userLoginPatch]: Services.customerinfo,
	[CustomerinfoMethods.userGet]: Services.customerinfo,
	[CustomerinfoMethods.clientsCardFullResponseGet]: Services.customerinfo,
	[CustomerinfoMethods.consentsGet]: Services.customerinfo,
	[CustomerinfoMethods.consentsPost]: Services.customerinfo,
	[CustomerinfoMethods.deviceGet]: Services.customerinfo,
	[CustomerinfoMethods.deviceChangePatch]: Services.customerinfo,
	[CustomerinfoMethods.deviceDeactivatePatch]: Services.customerinfo,
	[CustomerinfoMethods.extendedUserPatch]: Services.customerinfo,
	[CustomerinfoMethods.extendedUserInfoGet]: Services.customerinfo,
	[CustomerinfoMethods.legalAccountGet]: Services.customerinfo,
	[CustomerinfoMethods.legalInfoGet]: Services.customerinfo,
	[DeeplinkMethods.longGet]: Services.deeplink,
	[DeeplinkMethods.universalGet]: Services.deeplink,
	[DemandpayMethods.insuranceCompanyGet]: Services.demandpay,
	[DemandpayMethods.insuranceRequisitesPost]: Services.demandpay,
	[DemandpayMethods.legalPaymentPost]: Services.demandpay,
	[DemandpayMethods.legalPaymentPut]: Services.demandpay,
	[DemandpayMethods.paymentExecutedPatch]: Services.demandpay,
	[DialogsMethods.chatMessagesPut]: Services.dialogs,
	[DialogsMethods.omnichatTokenPost]: Services.dialogs,
	[DialogsMethods.omnichatActionPost]: Services.dialogs,
	[DialogsMethods.chatMessagePost]: Services.dialogs,
	[DialogsMethods.omnichatSettingGet]: Services.dialogs,
	[DialogsMethods.chatGet]: Services.dialogs,
	[DialogsMethods.chatDetailsGet]: Services.dialogs,
	[DialogsMethods.chatDelete]: Services.dialogs,
	[FileUploadMethods.fileUploadGet]: Services.fileUpload,
	[FileUploadMethods.fileUploadPost]: Services.fileUpload,
	[FinanceMethods.carloanLinkPost]: Services.finance,
	[FinanceMethods.financePatch]: Services.finance,
	[FinanceMethods.mortgageGet]: Services.finance,
	[FinanceMethods.mortgagePatch]: Services.finance,
	[FinanceMethods.carloanGet]: Services.finance,
	[FinanceMethods.carloanPatch]: Services.finance,
	[FinanceMethods.financeGet]: Services.finance,
	[FinanceMethods.restructuringLinkGet]: Services.finance,
	[FinanceMethods.requisitesGet]: Services.finance,
	[FinanceMethods.detailsGet]: Services.finance,
	[FinanceMethods.actionsGet]: Services.finance,
	[FinanceMethods.savingsGet]: Services.finance,
	[FinanceMethods.savingsDetailsGet]: Services.finance,
	[FinanceMethods.mortgageLinkGet]: Services.finance,
	[FinanceMethods.investRedemptionSumsGet]: Services.finance,
	[FinanceMethods.investPaymentScheduleGet]: Services.finance,
	[FinanceMethods.investDetailsGet]: Services.finance,
	[FinanceMethods.insuranceGet]: Services.finance,
	[FinanceMethods.insuranceHintGet]: Services.finance,
	[FinanceMethods.depositGet]: Services.finance,
	[FinanceMethods.currentGet]: Services.finance,
	[FinanceMethods.currentDetailsGet]: Services.finance,
	[FinanceMethods.creditGet]: Services.finance,
	[FinanceMethods.creditScheduleGet]: Services.finance,
	[FinanceMethods.carloanLinkGet]: Services.finance,
	[FinanceMethods.accountGet]: Services.finance,
	[InstantpayMethods.personPhoneGet]: Services.instantpay,
	[InstantpayMethods.personPhonePut]: Services.instantpay,
	[InstantpayMethods.personPhonePatch]: Services.instantpay,
	[InstantpayMethods.inputDocumentPut]: Services.instantpay,
	[InstantpayMethods.personPhonePost]: Services.instantpay,
	[InstantpayMethods.personPhoneTechnicalPost]: Services.instantpay,
	[InstantpayMethods.personPhoneByTemplatePost]: Services.instantpay,
	[InstantpayMethods.outputCheckPost]: Services.instantpay,
	[InstantpayMethods.inputDocumentPost]: Services.instantpay,
	[InstantpayMethods.directLinkPost]: Services.instantpay,
	[InstantpayMethods.personPhoneSettledPatch]: Services.instantpay,
	[InstantpayMethods.personPhoneExecutedPatch]: Services.instantpay,
	[InstantpayMethods.outputDocumentUpdatePatch]: Services.instantpay,
	[InstantpayMethods.outputDocumentExecutePatch]: Services.instantpay,
	[InstantpayMethods.outputDocumentCancelPatch]: Services.instantpay,
	[InstantpayMethods.inputDocumentConfirmPayPatch]: Services.instantpay,
	[InstantpayMethods.phoneinfoPayeeGet]: Services.instantpay,
	[InstantpayMethods.outputDocumentGet]: Services.instantpay,
	[InstantpayMethods.memberMe2meGet]: Services.instantpay,
	[InstantpayMethods.freeLimitGet]: Services.instantpay,
	[InstantpayMethods.banklistGet]: Services.instantpay,
	[InstantpayMethods.countryCodeGet]: Services.instantpay,
	[LegalpayMethods.paymentExecutedPatch]: Services.legalpay,
	[LegalpayMethods.paymentFeecalculatePut]: Services.legalpay,
	[LegalpayMethods.paymentSettledPatch]: Services.legalpay,
	[LegalpayMethods.sbpC2bAwPost]: Services.legalpay,
	[LegalpayMethods.acknowledgementPatch]: Services.legalpay,
	[LegalpayMethods.petitionPost]: Services.legalpay,
	[LimitMethods.customerGet]: Services.limit,
	[LimitMethods.customerSingleLimitGet]: Services.limit,
	[LocalizationMethods.localization_channel_Post]: Services.localization,
	[LocalizationMethods.localization_channel_Key_key_Get]: Services.localization,
	[LocalizationMethods.localizationVersion_version_Get]: Services.localization,
	[LoyaltyMethods.loyaltyGet]: Services.loyalty,
	[LoyaltyMethods.bannersGet]: Services.loyalty,
	[LoyaltyMethods.bonushistoryGet]: Services.loyalty,
	[LoyaltyMethods.bonushistoryPageGet]: Services.loyalty,
	[LoyaltyMethods.cardGet]: Services.loyalty,
	[LoyaltyMethods.cardCategoryGet]: Services.loyalty,
	[LoyaltyMethods.cardPost]: Services.loyalty,
	[LoyaltyMethods.categoriesGet]: Services.loyalty,
	[LoyaltyMethods.categoryOffersGet]: Services.loyalty,
	[LoyaltyMethods.memberProgramGet]: Services.loyalty,
	[LoyaltyMethods.memberProgramBalanceInfoGet]: Services.loyalty,
	[LoyaltyMethods.offersGet]: Services.loyalty,
	[LoyaltyMethods.offersFavouriteGet]: Services.loyalty,
	[LoyaltyMethods.offersDetailsGet]: Services.loyalty,
	[LoyaltyMethods.offersFavouritePut]: Services.loyalty,
	[LoyaltyMethods.offersFavouritePost]: Services.loyalty,
	[LoyaltyMethods.operationsGet]: Services.loyalty,
	[LoyaltyMethods.travelurlGet]: Services.loyalty,
	[LoyaltyMethods.actionGet]: Services.loyalty,
	[LoyaltyMethods.categoryGet]: Services.loyalty,
	[LoyaltyMethods.categoryPatch]: Services.loyalty,
	[LoyaltyMethods.deactivatePatch]: Services.loyalty,
	[LoyaltyMethods.graphGet]: Services.loyalty,
	[LoyaltyMethods.ruleGet]: Services.loyalty,
	[LoyaltyMethods.switchPatch]: Services.loyalty,
	[LoyaltyMethods.programFAQGet]: Services.loyalty,
	[MortgageMethods.cardOrderPost]: Services.mortgage,
	[MortgageMethods.mortgageGet]: Services.mortgage,
	[MortgageMethods.mortgagePatch]: Services.mortgage,
	[MortgageMethods.linkGet]: Services.mortgage,
	[MortgageMethods.accountRequisitesGet]: Services.mortgage,
	[MortgageMethods.paymentsGet]: Services.mortgage,
	[NotificationMethods.notificationGet]: Services.notification,
	[NotificationMethods.deletedPut]: Services.notification,
	[NotificationMethods.documentListGet]: Services.notification,
	[NotificationMethods.informedPut]: Services.notification,
	[NotificationMethods.messageDeletedPut]: Services.notification,
	[NotificationMethods.messageHistoryGet]: Services.notification,
	[NotificationMethods.messageHistoryReadPatch]: Services.notification,
	[NotificationMethods.readPut]: Services.notification,
	[NotificationMethods.splashAllGet]: Services.notification,
	[NotificationMethods.splashStyleGet]: Services.notification,
	[NotificationMethods.splashBackwardedPatch]: Services.notification,
	[NotificationMethods.splashClosedPatch]: Services.notification,
	[NotificationMethods.splashInformedPatch]: Services.notification,
	[NotificationMethods.splashReadPatch]: Services.notification,
	[NotificationMethods.technicalGet]: Services.notification,
	[NotificationMethods.technicalCountPut]: Services.notification,
	[OperationTemplateMethods.templateGet]: Services.operationTemplate,
	[OperationTemplateMethods.templatePut]: Services.operationTemplate,
	[OperationTemplateMethods.templateDelete]: Services.operationTemplate,
	[OperationTemplateMethods.templatePatch]: Services.operationTemplate,
	[OperationTemplateMethods.templatePost]: Services.operationTemplate,
	[OperationTemplateMethods.statementPost]: Services.operationTemplate,
	[OperationTemplateMethods.statementCancelledPost]: Services.operationTemplate,
	[OperationTemplateMethods.regularpaymentorderSuspendPatch]: Services.operationTemplate,
	[OperationTemplateMethods.vendorGet]: Services.operationTemplate,
	[OperationTemplateMethods.vendorDetailsGet]: Services.operationTemplate,
	[OperationTemplateMethods.logoGet]: Services.operationTemplate,
	[PaperlessOfficeMethods.documentPost]: Services.paperlessOffice,
	[PaperlessOfficeMethods.documentGet]: Services.paperlessOffice,
	[PaperlessOfficeMethods.issuePost]: Services.paperlessOffice,
	[PaperlessOfficeMethods.issueExecutePatch]: Services.paperlessOffice,
	[PaperlessOfficeMethods.relatedProductsGet]: Services.paperlessOffice,
	[PaperlessOfficeMethods.relatedProductsConfirmPatch]: Services.paperlessOffice,
	[ProductCatalogMethods.contentsGet]: Services.productCatalog,
	[ProductCatalogMethods.digitalCardOrderAvailableGet]: Services.productCatalog,
	[ProductCatalogMethods.feedGet]: Services.productCatalog,
	[ProductCatalogMethods.propositionContentGet]: Services.productCatalog,
	[ProductCatalogMethods.propositionStyleGet]: Services.productCatalog,
	[ProductCatalogMethods.styleGet]: Services.productCatalog,
	[PushMethods.devicePost]: Services.push,
	[PushMethods.messageStatusPost]: Services.push,
	[PushMethods.mfmDevicePatch]: Services.push,
	[PushMethods.mfmDeviceGet]: Services.push,
	[QrrecognitionMethods.recognitionPost]: Services.qrrecognition,
	[RegistrationMethods.registrationPost]: Services.registration,
	[RegistrationMethods.webbyConfirmPost]: Services.registration,
	[RegistrationMethods.webbyOfferPost]: Services.registration,
	[RegistrationMethods.webbyOfferAgreementPost]: Services.registration,
	[RegistrationMethods.webbyOfferGet]: Services.registration,
	[RepaymentMethods.repaymentGet]: Services.repayment,
	[RepaymentMethods.repaymentPut]: Services.repayment,
	[RepaymentMethods.repaymentPost]: Services.repayment,
	[RepaymentMethods.executedPatch]: Services.repayment,
	[RequisitePayMethods.privatePaymentGet]: Services.requisitePay,
	[RequisitePayMethods.privatePaymentPut]: Services.requisitePay,
	[RequisitePayMethods.privatePaymentPatch]: Services.requisitePay,
	[RequisitePayMethods.privatePaymentPost]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentGet]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentPut]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentPatch]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentPost]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentPost]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentGet]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentPut]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentPatch]: Services.requisitePay,
	[RequisitePayMethods.paymentExecutedPatch]: Services.requisitePay,
	[RequisitePayMethods.dictionaryBicUniversalGet]: Services.requisitePay,
	[RequisitePayMethods.clientInnGet]: Services.requisitePay,
	[RequisitePayMethods.dictionaryBudgetGet]: Services.requisitePay,
	[RequisitePayMethods.clientDetailsGet]: Services.requisitePay,
	[RequisitePayMethods.dictionaryPayee_typeGet]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentByTemplatePost]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentByTemplatePost]: Services.requisitePay,
	[RequisitePayMethods.privatePaymentByTemplatePost]: Services.requisitePay,
	[RequisitePayMethods.contractPaymentPut]: Services.requisitePay,
	[RequisitePayMethods.contractPaymentPost]: Services.requisitePay,
	[RequisitePayMethods.contractPaymentByTemplatePost]: Services.requisitePay,
	[RequisitePayMethods.privatePaymentPartialUpdatePatch]: Services.requisitePay,
	[RequisitePayMethods.legalPaymentPartialUpdatePatch]: Services.requisitePay,
	[RequisitePayMethods.contractPaymentExecutedPatch]: Services.requisitePay,
	[RequisitePayMethods.budgetPaymentPartialUpdatePatch]: Services.requisitePay,
	[RequisitePayMethods.dictionaryCompanyGet]: Services.requisitePay,
	[RequisitePayMethods.dictionaryBicGet]: Services.requisitePay,
	[RequisitePayMethods.dictionaryBicRuledGet]: Services.requisitePay,
	[SaleMethods.authForwardGet]: Services.sale,
	[SaleMethods.authForwardPost]: Services.sale,
	[SaleMethods.authP2uGet]: Services.sale,
	[SaleMethods.cardUpdateGet]: Services.sale,
	[SaleMethods.staticStyleGet]: Services.sale,
	[SaleMethods.staticStyleHead]: Services.sale,
	[SaleMethods.staticStylePost]: Services.sale,
	[SaleMethods.staticStylePut]: Services.sale,
	[SaleMethods.staticStyleDelete]: Services.sale,
	[SaleMethods.staticStyleOptions]: Services.sale,
	[SaleMethods.staticStylePatch]: Services.sale,
	[SavingMethods.accountIssuePost]: Services.saving,
	[SavingMethods.accountIssuePut]: Services.saving,
	[SavingMethods.accountIssueExecutedPatch]: Services.saving,
	[SavingMethods.accountBannerGet]: Services.saving,
	[SavingMethods.bonusAccountsGet]: Services.saving,
	[SavingMethods.bonusConditionGet]: Services.saving,
	[SavingMethods.bonusDescriptionGet]: Services.saving,
	[SavingMethods.bonusMccGet]: Services.saving,
	[SavingMethods.bonusDetailsGet]: Services.saving,
	[SavingMethods.bonusHistoryGet]: Services.saving,
	[SavingMethods.bonusOrderPost]: Services.saving,
	[SavingMethods.depositCalculationGet]: Services.saving,
	[SavingMethods.depositCloseDepositIssueExecutedPatch]: Services.saving,
	[SavingMethods.depositDocumentPut]: Services.saving,
	[SavingMethods.depositDocumentConditionsGet]: Services.saving,
	[SavingMethods.depositIssuePost]: Services.saving,
	[SavingMethods.depositIssuePut]: Services.saving,
	[SavingMethods.depositIssueExecutedPatch]: Services.saving,
	[SavingMethods.depositIssueFormGet]: Services.saving,
	[SavingMethods.depositIssuePreconditionGet]: Services.saving,
	[SavingMethods.depositIssueSearchGet]: Services.saving,
	[SavingMethods.depositIssueThresholdGet]: Services.saving,
	[SavingMethods.depositOrderPost]: Services.saving,
	[SavingMethods.depositProfitGet]: Services.saving,
	[SavingMethods.depositWithdrawIssueExecutedPatch]: Services.saving,
	[SavingMethods.depositCloseDepositIssuePost]: Services.saving,
	[SavingMethods.depositWithdrawIssuePost]: Services.saving,
	[SavingMethods.depositConditionsPdfPreparationGet]: Services.saving,
	[SavingMethods.depositConditionsPdfGet]: Services.saving,
	[SavingMethods.openAccountInfoGet]: Services.saving,
	[ServicePayMethods.directInvoicePost]: Services.servicePay,
	[ServicePayMethods.directInvoicePut]: Services.servicePay,
	[ServicePayMethods.directPaymentPost]: Services.servicePay,
	[ServicePayMethods.directPaymentGet]: Services.servicePay,
	[ServicePayMethods.directPaymentPut]: Services.servicePay,
	[ServicePayMethods.directPaymentExecutedPatch]: Services.servicePay,
	[ServicePayMethods.invoicePost]: Services.servicePay,
	[ServicePayMethods.invoicePut]: Services.servicePay,
	[ServicePayMethods.paymentPost]: Services.servicePay,
	[ServicePayMethods.paymentGet]: Services.servicePay,
	[ServicePayMethods.paymentPut]: Services.servicePay,
	[ServicePayMethods.paymentExecutedPatch]: Services.servicePay,
	[ServicePayMethods.paymentRepeatGet]: Services.servicePay,
	[ServicePointMethods.listPost]: Services.servicePoint,
	[ServicePointMethods.mapPost]: Services.servicePoint,
	[ServicePointMethods.officeDetailsGet]: Services.servicePoint,
	[ServicePointMethods.officeTimeTableGet]: Services.servicePoint,
	[StatementMethods.personalPost]: Services.statement,
	[StatementMethods.accountStatementPost]: Services.statement,
	[StatementMethods.accountStatementFilePost]: Services.statement,
	[StatementMethods.statementGet]: Services.statement,
	[StatementMethods.personalAvailableStatementsGet]: Services.statement,
	[StatementMethods.cardStatementGet]: Services.statement,
	[StatementMethods.accountStatementGet]: Services.statement,
	[StatementMethods.accountStatementFileGet]: Services.statement,
	[TariffMethods.priorityGet]: Services.tariff,
	[TariffMethods.priorityPassesGet]: Services.tariff,
	[TariffMethods.tariffGet]: Services.tariff,
	[TariffMethods.tariffReceiptGet]: Services.tariff,
	[VendorMethods.categoryGet]: Services.vendor,
	[VendorMethods.categoryProductGet]: Services.vendor,
	[VendorMethods.productGet]: Services.vendor,
	[VendorMethods.productSettingsGet]: Services.vendor,
};

/**
* @description PathToServiceAndMethodMap.
*/
export const PathToServiceAndMethodMap = {
	'GET_/accountpay/v3/account/accountNumber/_accountNumber_': {
		service: Services.accountPay,
		method: AccountPayMethods.accountAccountNumberGet
	},
	'GET_/accountpay/v3/account/cardNumber/_cardNumber_': {
		service: Services.accountPay,
		method: AccountPayMethods.accountCardNumberGet
	},
	'GET_/accountpay/v3/account/payment/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.accountPaymentGet
	},
	'GET_/accountpay/v3/account/phone/_phoneNumber_': {
		service: Services.accountPay,
		method: AccountPayMethods.accountPhoneGet
	},
	'POST_/accountpay/v3/client/account': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountPost
	},
	'POST_/accountpay/v3/client/account/byTemplate/_templateId_': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountByTemplatePost
	},
	'GET_/accountpay/v3/client/account/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountGet
	},
	'PUT_/accountpay/v3/client/account/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountPut
	},
	'PATCH_/accountpay/v3/client/account/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountPatch
	},
	'PATCH_/accountpay/v3/client/account/_paymentId_/executed': {
		service: Services.accountPay,
		method: AccountPayMethods.clientAccountExecutedPatch
	},
	'PATCH_/accountpay/v3/client/partialUpdate/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.clientPartialUpdatePatch
	},
	'POST_/accountpay/v3/deposit': {
		service: Services.accountPay,
		method: AccountPayMethods.depositPost
	},
	'GET_/accountpay/v3/deposit/_accountCode_/minimumContribution': {
		service: Services.accountPay,
		method: AccountPayMethods.depositMinimumContributionGet
	},
	'PUT_/accountpay/v3/deposit/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.depositPut
	},
	'PATCH_/accountpay/v3/deposit/_paymentId_/executed': {
		service: Services.accountPay,
		method: AccountPayMethods.depositExecutedPatch
	},
	'POST_/accountpay/v3/exchange': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangePost
	},
	'GET_/accountpay/v3/exchange/mainCurrency': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangeMainCurrencyGet
	},
	'GET_/accountpay/v3/exchange/_currencyCodeFrom_/_currencyCodeTo_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangeGet
	},
	'GET_/accountpay/v3/exchange/_exchangeId_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangeGet
	},
	'PUT_/accountpay/v3/exchange/_exchangeId_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangePut
	},
	'PATCH_/accountpay/v3/exchange/_exchangeId_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangePatch
	},
	'PATCH_/accountpay/v3/exchange/_exchangeId_/executed': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangeExecutedPatch
	},
	'POST_/accountpay/v3/own': {
		service: Services.accountPay,
		method: AccountPayMethods.ownPost
	},
	'POST_/accountpay/v3/own/byTemplate/_templateId_': {
		service: Services.accountPay,
		method: AccountPayMethods.ownByTemplatePost
	},
	'PATCH_/accountpay/v3/own/partialUpdate/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.ownPartialUpdatePatch
	},
	'GET_/accountpay/v3/own/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.ownGet
	},
	'PUT_/accountpay/v3/own/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.ownPut
	},
	'PATCH_/accountpay/v3/own/_paymentId_': {
		service: Services.accountPay,
		method: AccountPayMethods.ownPatch
	},
	'PATCH_/accountpay/v3/own/_paymentId_/executed': {
		service: Services.accountPay,
		method: AccountPayMethods.ownExecutedPatch
	},
	'GET_/accountpay/v3/exchangedeal/_id_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangedealGet
	},
	'PUT_/accountpay/v3/exchangedeal/_id_': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangedealPut
	},
	'POST_/accountpay/v3/exchangedeal': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangedealPost
	},
	'POST_/accountpay/v3/exchangedeal/list': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangedealListPost
	},
	'PATCH_/accountpay/v3/exchangedeal/_id_/execute': {
		service: Services.accountPay,
		method: AccountPayMethods.exchangedealExecutePatch
	},
	'GET_/actions/v2': {
		service: Services.actions,
		method: ActionsMethods.actionsGet
	},
	'POST_/audit/v0/event': {
		service: Services.audit,
		method: AuditMethods.eventPost
	},
	'POST_/audit/v0/review': {
		service: Services.audit,
		method: AuditMethods.reviewPost
	},
	'POST_/audit/v0/runtime': {
		service: Services.audit,
		method: AuditMethods.runtimePost
	},
	'POST_/auth/v4/oauth/authorize': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthAuthorizePost
	},
	'GET_/auth/v4/oauth/info': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthInfoGet
	},
	'POST_/auth/v4/oauth/check_token': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthCheck_tokenPost
	},
	'POST_/auth/v4/oauth/login': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthLoginPost
	},
	'PATCH_/auth/v4/oauth/logout': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthLogoutPatch
	},
	'POST_/auth/v4/oauth/token': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthTokenPost
	},
	'POST_/auth/v4/oauth/pin': {
		service: Services.authorization,
		method: AuthorizationMethods.oauthPinPost
	},
	'POST_/auth/v4/credential/initial': {
		service: Services.authorization,
		method: AuthorizationMethods.credentialInitialPost
	},
	'POST_/auth/v4/credential/register': {
		service: Services.authorization,
		method: AuthorizationMethods.credentialRegisterPost
	},
	'POST_/auth/v4/credential/challenge': {
		service: Services.authorization,
		method: AuthorizationMethods.credentialChallengePost
	},
	'POST_/autocar/v0/autoteka/report/vehicle': {
		service: Services.autocar,
		method: AutocarMethods.autotekaReportVehiclePost
	},
	'GET_/autocar/v0/autoteka/report/_reportId_/vehicle': {
		service: Services.autocar,
		method: AutocarMethods.autotekaReportVehicleGet
	},
	'GET_/autocar/v0/carLoanInfo': {
		service: Services.autocar,
		method: AutocarMethods.carLoanInfoGet
	},
	'GET_/autocar/v0/dictionary/autoBrand': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryAutoBrandGet
	},
	'GET_/autocar/v0/dictionary/autoModel': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryAutoModelGet
	},
	'GET_/autocar/v0/dictionary/autoModification': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryAutoModificationGet
	},
	'GET_/autocar/v0/dictionary/autocardInfo': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryAutocardInfoGet
	},
	'GET_/autocar/v0/dictionary/bank': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryBankGet
	},
	'GET_/autocar/v0/dictionary/credentialType': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryCredentialTypeGet
	},
	'GET_/autocar/v0/dictionary/region': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryRegionGet
	},
	'GET_/autocar/v0/dictionary/vehicleInfo': {
		service: Services.autocar,
		method: AutocarMethods.dictionaryVehicleInfoGet
	},
	'GET_/autocar/v0/file/agreement': {
		service: Services.autocar,
		method: AutocarMethods.fileAgreementGet
	},
	'POST_/autocar/v0/insuranceInvoiceOverduePenalty': {
		service: Services.autocar,
		method: AutocarMethods.insuranceInvoiceOverduePenaltyPost
	},
	'GET_/autocar/v0/issue': {
		service: Services.autocar,
		method: AutocarMethods.issueGet
	},
	'POST_/autocar/v0/issue': {
		service: Services.autocar,
		method: AutocarMethods.issuePost
	},
	'PATCH_/autocar/v0/issue/_issueId_/auto': {
		service: Services.autocar,
		method: AutocarMethods.issueAutoPatch
	},
	'PATCH_/autocar/v0/issue/_issueId_/calculate': {
		service: Services.autocar,
		method: AutocarMethods.issueCalculatePatch
	},
	'PATCH_/autocar/v0/issue/_issueId_/confirm': {
		service: Services.autocar,
		method: AutocarMethods.issueConfirmPatch
	},
	'PATCH_/autocar/v0/issue/_issueId_/drivers': {
		service: Services.autocar,
		method: AutocarMethods.issueDriversPatch
	},
	'PATCH_/autocar/v0/issue/_issueId_/import': {
		service: Services.autocar,
		method: AutocarMethods.issueImportPatch
	},
	'PATCH_/autocar/v0/issue/_issueId_/personalData': {
		service: Services.autocar,
		method: AutocarMethods.issuePersonalDataPatch
	},
	'POST_/autocar/v0/payment': {
		service: Services.autocar,
		method: AutocarMethods.paymentPost
	},
	'PUT_/autocar/v0/payment/_paymentId_': {
		service: Services.autocar,
		method: AutocarMethods.paymentPut
	},
	'PATCH_/autocar/v0/payment/_paymentId_/executed': {
		service: Services.autocar,
		method: AutocarMethods.paymentExecutedPatch
	},
	'GET_/billboard/v2/advertising': {
		service: Services.billboard,
		method: BillboardMethods.advertisingGet
	},
	'DELETE_/billboard/v2/advertising/_id_': {
		service: Services.billboard,
		method: BillboardMethods.advertisingDelete
	},
	'PUT_/billboard/v2/advertising/_id_/count': {
		service: Services.billboard,
		method: BillboardMethods.advertisingCountPut
	},
	'PUT_/billboard/v2/advertising/_id_/tap': {
		service: Services.billboard,
		method: BillboardMethods.advertisingTapPut
	},
	'GET_/billboard/v2/privilege/content': {
		service: Services.billboard,
		method: BillboardMethods.privilegeContentGet
	},
	'GET_/billboard/v2/productcatalog/content': {
		service: Services.billboard,
		method: BillboardMethods.productcatalogContentGet
	},
	'GET_/billboard/v2/productcatalog/style': {
		service: Services.billboard,
		method: BillboardMethods.productcatalogStyleGet
	},
	'GET_/billboard/v2/recommendedProduct': {
		service: Services.billboard,
		method: BillboardMethods.recommendedProductGet
	},
	'GET_/billboard/v2/recommendedProduct/invitations': {
		service: Services.billboard,
		method: BillboardMethods.recommendedProductInvitationsGet
	},
	'GET_/billboard/v2/recommendedProduct/_productId_': {
		service: Services.billboard,
		method: BillboardMethods.recommendedProductGet
	},
	'GET_/billboard/v2/slider/content': {
		service: Services.billboard,
		method: BillboardMethods.sliderContentGet
	},
	'GET_/billboard/v2/style': {
		service: Services.billboard,
		method: BillboardMethods.styleGet
	},
	'GET_/billboard/v2/tetris': {
		service: Services.billboard,
		method: BillboardMethods.tetrisGet
	},
	'POST_/callback/v0/callback': {
		service: Services.callback,
		method: CallbackMethods.callbackPost
	},
	'GET_/card/v3': {
		service: Services.card,
		method: CardMethods.cardGet
	},
	'GET_/card/v3/alias': {
		service: Services.card,
		method: CardMethods.aliasGet
	},
	'PUT_/card/v3/alias/_aliasId_': {
		service: Services.card,
		method: CardMethods.aliasPut
	},
	'DELETE_/card/v3/alias/_aliasId_': {
		service: Services.card,
		method: CardMethods.aliasDelete
	},
	'GET_/card/v3/digital/available': {
		service: Services.card,
		method: CardMethods.digitalAvailableGet
	},
	'PATCH_/card/v3/digital/cancel/_id_': {
		service: Services.card,
		method: CardMethods.digitalCancelPatch
	},
	'POST_/card/v3/digital/issue': {
		service: Services.card,
		method: CardMethods.digitalIssuePost
	},
	'PUT_/card/v3/digital/issue/_id_': {
		service: Services.card,
		method: CardMethods.digitalIssuePut
	},
	'GET_/card/v3/_cardId_': {
		service: Services.card,
		method: CardMethods.cardGet
	},
	'PUT_/card/v3/_cardId_': {
		service: Services.card,
		method: CardMethods.cardPut
	},
	'POST_/card/v3/_cardId_/activate': {
		service: Services.card,
		method: CardMethods.activatePost
	},
	'PATCH_/card/v3/_cardId_/activate/canceled': {
		service: Services.card,
		method: CardMethods.activateCanceledPatch
	},
	'PATCH_/card/v3/_cardId_/activate/executed': {
		service: Services.card,
		method: CardMethods.activateExecutedPatch
	},
	'PATCH_/card/v3/_cardId_/changeAccount': {
		service: Services.card,
		method: CardMethods.changeAccountPatch
	},
	'POST_/card/v3/_cardId_/changepin': {
		service: Services.card,
		method: CardMethods.changepinPost
	},
	'PATCH_/card/v3/_cardId_/changepin/canceled': {
		service: Services.card,
		method: CardMethods.changepinCanceledPatch
	},
	'PATCH_/card/v3/_cardId_/changepin/executed': {
		service: Services.card,
		method: CardMethods.changepinExecutedPatch
	},
	'GET_/card/v3/_cardId_/limit': {
		service: Services.card,
		method: CardMethods.limitGet
	},
	'PATCH_/card/v3/_cardId_/limit': {
		service: Services.card,
		method: CardMethods.limitPatch
	},
	'GET_/card/v3/_cardId_/operation/limit': {
		service: Services.card,
		method: CardMethods.operationLimitGet
	},
	'POST_/card/v3/_cardId_/requisites': {
		service: Services.card,
		method: CardMethods.requisitesPost
	},
	'PATCH_/card/v3/_cardId_/status': {
		service: Services.card,
		method: CardMethods.statusPatch
	},
	'GET_/card/v3/_cardNumber_/detail': {
		service: Services.card,
		method: CardMethods.detailGet
	},
	'POST_/card/v3/_cardId_/status': {
		service: Services.card,
		method: CardMethods.statusPost
	},
	'GET_/card/v3/productUid/_productUid_/details': {
		service: Services.card,
		method: CardMethods.productUidDetailsGet
	},
	'GET_/card/v3/corporate': {
		service: Services.card,
		method: CardMethods.corporateGet
	},
	'PUT_/cardIssue/v0/link/issue/_issueId_': {
		service: Services.cardIssue,
		method: CardIssueMethods.linkIssuePut
	},
	'PUT_/cardIssue/v0/digital/issue/_id_': {
		service: Services.cardIssue,
		method: CardIssueMethods.digitalIssuePut
	},
	'POST_/cardIssue/v0/_cardNumber_/processStage/changed': {
		service: Services.cardIssue,
		method: CardIssueMethods.processStageChangedPost
	},
	'POST_/cardIssue/v0/link/issue': {
		service: Services.cardIssue,
		method: CardIssueMethods.linkIssuePost
	},
	'POST_/cardIssue/v0/digital/issue': {
		service: Services.cardIssue,
		method: CardIssueMethods.digitalIssuePost
	},
	'PATCH_/cardIssue/v0/processStage/changed/_issueId_/executed': {
		service: Services.cardIssue,
		method: CardIssueMethods.processStageChangedExecutedPatch
	},
	'PATCH_/cardIssue/v0/processStage/changed/_issueId_/cancelled': {
		service: Services.cardIssue,
		method: CardIssueMethods.processStageChangedCancelledPatch
	},
	'PATCH_/cardIssue/v0/link/issue/_issueId_/executed': {
		service: Services.cardIssue,
		method: CardIssueMethods.linkIssueExecutedPatch
	},
	'PATCH_/cardIssue/v0/link/issue/_issueId_/cancelled': {
		service: Services.cardIssue,
		method: CardIssueMethods.linkIssueCancelledPatch
	},
	'PATCH_/cardIssue/v0/digital/cancel/_id_': {
		service: Services.cardIssue,
		method: CardIssueMethods.digitalCancelPatch
	},
	'GET_/cardIssue/v0/digital/available': {
		service: Services.cardIssue,
		method: CardIssueMethods.digitalAvailableGet
	},
	'GET_/cardIssue/v0/cardOrder': {
		service: Services.cardIssue,
		method: CardIssueMethods.cardOrderGet
	},
	'GET_/cardIssue/v0/cardOrder/order/_orderId_': {
		service: Services.cardIssue,
		method: CardIssueMethods.cardOrderOrderGet
	},
	'GET_/cardIssue/v0/cardOrder/delivery/cards': {
		service: Services.cardIssue,
		method: CardIssueMethods.cardOrderDeliveryCardsGet
	},
	'GET_/cardIssue/v0/card/_cardPAN_/order': {
		service: Services.cardIssue,
		method: CardIssueMethods.cardOrderGet
	},
	'GET_/cardpay/v1/payment/_paymentId_': {
		service: Services.cardPay,
		method: CardPayMethods.paymentGet
	},
	'PUT_/cardpay/v1/payment/_paymentId_': {
		service: Services.cardPay,
		method: CardPayMethods.paymentPut
	},
	'PATCH_/cardpay/v1/payment/_paymentId_': {
		service: Services.cardPay,
		method: CardPayMethods.paymentPatch
	},
	'POST_/cardpay/v1/twpg/_result_/_paymentId__': {
		service: Services.cardPay,
		method: CardPayMethods.twpgPost
	},
	'POST_/cardpay/v1/payment': {
		service: Services.cardPay,
		method: CardPayMethods.paymentPost
	},
	'POST_/cardpay/v1/payment/twpg': {
		service: Services.cardPay,
		method: CardPayMethods.paymentTwpgPost
	},
	'POST_/cardpay/v1/payment/byTemplate/_templateId_': {
		service: Services.cardPay,
		method: CardPayMethods.paymentByTemplatePost
	},
	'PATCH_/cardpay/v1/payment/_paymentId_/executed': {
		service: Services.cardPay,
		method: CardPayMethods.paymentExecutedPatch
	},
	'PATCH_/cardpay/v1/payment/_paymentId_/cres': {
		service: Services.cardPay,
		method: CardPayMethods.paymentCresPatch
	},
	'PATCH_/cardpay/v1/payment/_paymentId_/areq': {
		service: Services.cardPay,
		method: CardPayMethods.paymentAreqPatch
	},
	'PATCH_/cardpay/v1/payment/partialUpdate/_paymentId_': {
		service: Services.cardPay,
		method: CardPayMethods.paymentPartialUpdatePatch
	},
	'GET_/cardpay/v1/payment/_paymentId_/status': {
		service: Services.cardPay,
		method: CardPayMethods.paymentStatusGet
	},
	'GET_/cardpay/v1/check/country/_countryCode_': {
		service: Services.cardPay,
		method: CardPayMethods.checkCountryGet
	},
	'GET_/cardpay/v1/cardInfo/_cardNumber_': {
		service: Services.cardPay,
		method: CardPayMethods.cardInfoGet
	},
	'GET_/cardpay/v1/cardInfo/client/_cardNumber_': {
		service: Services.cardPay,
		method: CardPayMethods.cardInfoClientGet
	},
	'GET_/cardpay/v1/aliasInfo/client/_aliasId_': {
		service: Services.cardPay,
		method: CardPayMethods.aliasInfoClientGet
	},
	'POST_/carloan/v0/cardOrder': {
		service: Services.carloan,
		method: CarloanMethods.cardOrderPost
	},
	'POST_/carloan/v0/finService/request': {
		service: Services.carloan,
		method: CarloanMethods.finServiceRequestPost
	},
	'GET_/carloan/v0/notes': {
		service: Services.carloan,
		method: CarloanMethods.notesGet
	},
	'GET_/carloan/v0/periodProductActionShow': {
		service: Services.carloan,
		method: CarloanMethods.periodProductActionShowGet
	},
	'GET_/charge/v3': {
		service: Services.charge,
		method: ChargeMethods.chargeGet
	},
	'GET_/charge/v3/': {
		service: Services.charge,
		method: ChargeMethods.chargeGet
	},
	'GET_/charge/v3/client': {
		service: Services.charge,
		method: ChargeMethods.clientGet
	},
	'GET_/charge/v3/count': {
		service: Services.charge,
		method: ChargeMethods.countGet
	},
	'GET_/charge/v3/currentUpcomingExpenses': {
		service: Services.charge,
		method: ChargeMethods.currentUpcomingExpensesGet
	},
	'POST_/charge/v3/gisgmp': {
		service: Services.charge,
		method: ChargeMethods.gisgmpPost
	},
	'POST_/charge/v3/giszkh': {
		service: Services.charge,
		method: ChargeMethods.giszkhPost
	},
	'GET_/charge/v3/mortgage/payments': {
		service: Services.charge,
		method: ChargeMethods.mortgagePaymentsGet
	},
	'GET_/charge/v3/mortgage/payments/_paymentId_': {
		service: Services.charge,
		method: ChargeMethods.mortgagePaymentsGet
	},
	'GET_/charge/v3/subscription': {
		service: Services.charge,
		method: ChargeMethods.subscriptionGet
	},
	'POST_/charge/v3/subscription': {
		service: Services.charge,
		method: ChargeMethods.subscriptionPost
	},
	'PUT_/charge/v3/subscription/_subscriptionId_': {
		service: Services.charge,
		method: ChargeMethods.subscriptionPut
	},
	'DELETE_/charge/v3/subscription/_subscriptionId_': {
		service: Services.charge,
		method: ChargeMethods.subscriptionDelete
	},
	'GET_/charge/v3/vendors': {
		service: Services.charge,
		method: ChargeMethods.vendorsGet
	},
	'PATCH_/charge/v3/_chargeId_': {
		service: Services.charge,
		method: ChargeMethods.chargePatch
	},
	'POST_/chargepay/v1/payment': {
		service: Services.chargepay,
		method: ChargepayMethods.paymentPost
	},
	'GET_/chargepay/v1/payment/_paymentId_': {
		service: Services.chargepay,
		method: ChargepayMethods.paymentGet
	},
	'PUT_/chargepay/v1/payment/_paymentId_': {
		service: Services.chargepay,
		method: ChargepayMethods.paymentPut
	},
	'PATCH_/chargepay/v1/payment/_paymentId_/executed': {
		service: Services.chargepay,
		method: ChargepayMethods.paymentExecutedPatch
	},
	'POST_/chargepay/v1/invoice': {
		service: Services.chargepay,
		method: ChargepayMethods.invoicePost
	},
	'PUT_/chargepay/v1/invoice/_invoiceId_/step/_stepName_': {
		service: Services.chargepay,
		method: ChargepayMethods.invoicePut
	},
	'GET_/chronology/v0': {
		service: Services.chronology,
		method: ChronologyMethods.chronologyGet
	},
	'GET_/chronology/v0/page/_pageNumber_': {
		service: Services.chronology,
		method: ChronologyMethods.pageGet
	},
	'GET_/chronology/v0/page/_pageNumber_/version': {
		service: Services.chronology,
		method: ChronologyMethods.pageVersionGet
	},
	'GET_/chronology/v0/payment': {
		service: Services.chronology,
		method: ChronologyMethods.paymentGet
	},
	'GET_/chronology/v0/payment/count': {
		service: Services.chronology,
		method: ChronologyMethods.paymentCountGet
	},
	'GET_/chronology/v0/payment/repeat': {
		service: Services.chronology,
		method: ChronologyMethods.paymentRepeatGet
	},
	'GET_/chronology/v0/payment/repeat/_uid_': {
		service: Services.chronology,
		method: ChronologyMethods.paymentRepeatGet
	},
	'GET_/chronology/v0/payment/_paymentId_': {
		service: Services.chronology,
		method: ChronologyMethods.paymentGet
	},
	'POST_/chronology/v0/payment/_paymentId_/type/_type_': {
		service: Services.chronology,
		method: ChronologyMethods.paymentPost
	},
	'PATCH_/chronology/v0/payment/_paymentId_/type/_type_/hide': {
		service: Services.chronology,
		method: ChronologyMethods.paymentHidePatch
	},
	'GET_/chronology/v0/pfm': {
		service: Services.chronology,
		method: ChronologyMethods.pfmGet
	},
	'GET_/chronology/v0/pfm/category/_category_': {
		service: Services.chronology,
		method: ChronologyMethods.pfmCategoryGet
	},
	'GET_/chronology/v0/pfm/group/_groupCode_': {
		service: Services.chronology,
		method: ChronologyMethods.pfmGroupGet
	},
	'GET_/chronology/v0/receipt/_uid_': {
		service: Services.chronology,
		method: ChronologyMethods.receiptGet
	},
	'GET_/chronology/v0/_uid_': {
		service: Services.chronology,
		method: ChronologyMethods.chronologyGet
	},
	'POST_/claims/v1/client/issue': {
		service: Services.claims,
		method: ClaimsMethods.clientIssuePost
	},
	'PATCH_/claims/v1/client/issue/_issueId_/execute': {
		service: Services.claims,
		method: ClaimsMethods.clientIssueExecutePatch
	},
	'GET_/claims/v1/client/personalData/addressType': {
		service: Services.claims,
		method: ClaimsMethods.clientPersonalDataAddressTypeGet
	},
	'GET_/claims/v1/client/personalData/availablePersonalRequisites': {
		service: Services.claims,
		method: ClaimsMethods.clientPersonalDataAvailablePersonalRequisitesGet
	},
	'POST_/claims/v1/client/personalData/issue': {
		service: Services.claims,
		method: ClaimsMethods.clientPersonalDataIssuePost
	},
	'PATCH_/claims/v1/client/personalData/issue/_issueId_/execute': {
		service: Services.claims,
		method: ClaimsMethods.clientPersonalDataIssueExecutePatch
	},
	'GET_/claims/v1/client/personalData/residenceType': {
		service: Services.claims,
		method: ClaimsMethods.clientPersonalDataResidenceTypeGet
	},
	'POST_/claims/v1/issue': {
		service: Services.claims,
		method: ClaimsMethods.issuePost
	},
	'PATCH_/claims/v1/issue/_issueId_/executed': {
		service: Services.claims,
		method: ClaimsMethods.issueExecutedPatch
	},
	'POST_/claims/v1/operationContest': {
		service: Services.claims,
		method: ClaimsMethods.operationContestPost
	},
	'GET_/claims/v1/operationContest/cases': {
		service: Services.claims,
		method: ClaimsMethods.operationContestCasesGet
	},
	'PATCH_/claims/v1/operationContest/_documentId_/execute': {
		service: Services.claims,
		method: ClaimsMethods.operationContestExecutePatch
	},
	'POST_/claims/v1/paymentDetails/issue': {
		service: Services.claims,
		method: ClaimsMethods.paymentDetailsIssuePost
	},
	'PATCH_/claims/v1/paymentDetails/issue/_issueId_/executed': {
		service: Services.claims,
		method: ClaimsMethods.paymentDetailsIssueExecutedPatch
	},
	'PATCH_/claims/v1/personal/statement': {
		service: Services.claims,
		method: ClaimsMethods.personalStatementPatch
	},
	'GET_/claims/v1/statement': {
		service: Services.claims,
		method: ClaimsMethods.statementGet
	},
	'PATCH_/claims/v1/statement': {
		service: Services.claims,
		method: ClaimsMethods.statementPatch
	},
	'POST_/claims/v1/statement/accountStatement': {
		service: Services.claims,
		method: ClaimsMethods.statementAccountStatementPost
	},
	'POST_/claims/v1/statement/accountStatement/file': {
		service: Services.claims,
		method: ClaimsMethods.statementAccountStatementFilePost
	},
	'GET_/claims/v1/statement/accountStatement/file/_statementDocumentId_': {
		service: Services.claims,
		method: ClaimsMethods.statementAccountStatementFileGet
	},
	'GET_/claims/v1/statement/accountStatement/_requestId_': {
		service: Services.claims,
		method: ClaimsMethods.statementAccountStatementGet
	},
	'GET_/claims/v1/statement/availableStatements': {
		service: Services.claims,
		method: ClaimsMethods.statementAvailableStatementsGet
	},
	'GET_/claims/v1/statement/params/_type_': {
		service: Services.claims,
		method: ClaimsMethods.statementParamsGet
	},
	'GET_/claims/v1/statement/personalAvailableStatements': {
		service: Services.claims,
		method: ClaimsMethods.statementPersonalAvailableStatementsGet
	},
	'GET_/claims/v1/type': {
		service: Services.claims,
		method: ClaimsMethods.typeGet
	},
	'GET_/client-cabinet/v0/content': {
		service: Services.clientCabinet,
		method: ClientCabinetMethods.contentGet
	},
	'PUT_/client-cabinet/v0/contentView': {
		service: Services.clientCabinet,
		method: ClientCabinetMethods.contentViewPut
	},
	'GET_/client-cabinet/v0/markers': {
		service: Services.clientCabinet,
		method: ClientCabinetMethods.markersGet
	},
	'POST_/closing-account/v0/document': {
		service: Services.closingAccount,
		method: ClosingAccountMethods.documentPost
	},
	'PATCH_/closing-account/v0/document/_documentId_/execute': {
		service: Services.closingAccount,
		method: ClosingAccountMethods.documentExecutePatch
	},
	'GET_/closing-account/v0/currency/check': {
		service: Services.closingAccount,
		method: ClosingAccountMethods.currencyCheckGet
	},
	'GET_/closing-account/v0/check': {
		service: Services.closingAccount,
		method: ClosingAccountMethods.checkGet
	},
	'GET_/communication/v0': {
		service: Services.communication,
		method: CommunicationMethods.communicationGet
	},
	'POST_/communication/v0/add': {
		service: Services.communication,
		method: CommunicationMethods.addPost
	},
	'GET_/communication/v0/addProfileInfo': {
		service: Services.communication,
		method: CommunicationMethods.addProfileInfoGet
	},
	'GET_/communication/v0/_profileId_': {
		service: Services.communication,
		method: CommunicationMethods.communicationGet
	},
	'POST_/communication/v0/_profileId_/delete': {
		service: Services.communication,
		method: CommunicationMethods.deletePost
	},
	'POST_/communication/v0/_profileId_/edit': {
		service: Services.communication,
		method: CommunicationMethods.editPost
	},
	'GET_/communication/v0/_profileId_/editProfileInfo': {
		service: Services.communication,
		method: CommunicationMethods.editProfileInfoGet
	},
	'POST_/concierge/v0/issue': {
		service: Services.concierge,
		method: ConciergeMethods.issuePost
	},
	'POST_/concierge/v0/email': {
		service: Services.concierge,
		method: ConciergeMethods.emailPost
	},
	'GET_/concierge/v0/content': {
		service: Services.concierge,
		method: ConciergeMethods.contentGet
	},
	'GET_/courier-delivery/v1/timeslots/_addressQuery_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.timeslotsGet
	},
	'POST_/courier-delivery/v1/order': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderPost
	},
	'PUT_/courier-delivery/v1/order/_orderId_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderPut
	},
	'PATCH_/courier-delivery/v1/order/_orderId_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderPatch
	},
	'GET_/courier-delivery/v1/order/_orderId_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderGet
	},
	'GET_/courier-delivery/v1/order/_orderId_/details': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderDetailsGet
	},
	'PATCH_/courier-delivery/v1/order/_orderId_/status': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.orderStatusPatch
	},
	'POST_/courier-delivery/v1/update': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.updatePost
	},
	'PUT_/courier-delivery/v1/card-delivery-cities': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.cardDeliveryCitiesPut
	},
	'POST_/courier-delivery/v1/card-delivery-cities': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.cardDeliveryCitiesPost
	},
	'GET_/courier-delivery/v1/card-delivery-cities/_cityOrIndex_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.cardDeliveryCitiesGet
	},
	'DELETE_/courier-delivery/v1/card-delivery-cities/_cityOrIndex_': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.cardDeliveryCitiesDelete
	},
	'POST_/courier-delivery/v1/card-delivery-cities-package': {
		service: Services.courierDelivery,
		method: CourierDeliveryMethods.cardDeliveryCitiesPackagePost
	},
	'PUT_/creditcard/v1/installment/_installmentId_': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentPut
	},
	'DELETE_/creditcard/v1/installment/_installmentId_': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentDelete
	},
	'POST_/creditcard/v1/installment': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentPost
	},
	'POST_/creditcard/v1/delivery': {
		service: Services.creditcard,
		method: CreditcardMethods.deliveryPost
	},
	'PATCH_/creditcard/v1/installment/_installmentId_/reduced': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentReducedPatch
	},
	'PATCH_/creditcard/v1/installment/_installmentId_/executed': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentExecutedPatch
	},
	'GET_/creditcard/v1/installment/_installmentId_/agreement/signed': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentAgreementSignedGet
	},
	'GET_/creditcard/v1/installment/_installmentId_/agreement/preview': {
		service: Services.creditcard,
		method: CreditcardMethods.installmentAgreementPreviewGet
	},
	'GET_/creditpay/v1/accountNumber/_accountNumber_/paymentType': {
		service: Services.creditpay,
		method: CreditpayMethods.accountNumberPaymentTypeGet
	},
	'PATCH_/creditpay/v1/payment/_paymentId_/executed': {
		service: Services.creditpay,
		method: CreditpayMethods.paymentExecutedPatch
	},
	'POST_/creditpay/v1/private/payment': {
		service: Services.creditpay,
		method: CreditpayMethods.privatePaymentPost
	},
	'PATCH_/creditpay/v1/private/payment/partialUpdate/_paymentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.privatePaymentPartialUpdatePatch
	},
	'PUT_/creditpay/v1/private/payment/_paymentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.privatePaymentPut
	},
	'GET_/creditpay/v1/product/_productId_/paymentType': {
		service: Services.creditpay,
		method: CreditpayMethods.productPaymentTypeGet
	},
	'GET_/mortgagepay/v0/authorization': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationGet
	},
	'POST_/mortgagepay/v0/authorization': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationPost
	},
	'PATCH_/mortgagepay/v0/authorization/_authorizationId_/authorized': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationAuthorizedPatch
	},
	'POST_/mortgagepay/v0/document': {
		service: Services.creditpay,
		method: CreditpayMethods.documentPost
	},
	'PATCH_/mortgagepay/v0/document/partialUpdate/_documentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.documentPartialUpdatePatch
	},
	'GET_/mortgagepay/v0/document/_documentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.documentGet
	},
	'PATCH_/mortgagepay/v0/document/_documentId_/confirmed': {
		service: Services.creditpay,
		method: CreditpayMethods.documentConfirmedPatch
	},
	'POST_/mortgagepay/v0/order': {
		service: Services.creditpay,
		method: CreditpayMethods.orderPost
	},
	'PATCH_/mortgagepay/v0/order/_orderId_/executed': {
		service: Services.creditpay,
		method: CreditpayMethods.orderExecutedPatch
	},
	'GET_/requisitepay/v1/authorization': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationGet
	},
	'POST_/requisitepay/v1/authorization': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationPost
	},
	'PATCH_/requisitepay/v1/authorization/_authorizationId_/authorized': {
		service: Services.creditpay,
		method: CreditpayMethods.authorizationAuthorizedPatch
	},
	'POST_/requisitepay/v1/document': {
		service: Services.creditpay,
		method: CreditpayMethods.documentPost
	},
	'PATCH_/requisitepay/v1/document/partialUpdate/_documentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.documentPartialUpdatePatch
	},
	'GET_/requisitepay/v1/document/_documentId_': {
		service: Services.creditpay,
		method: CreditpayMethods.documentGet
	},
	'PATCH_/requisitepay/v1/document/_documentId_/confirmed': {
		service: Services.creditpay,
		method: CreditpayMethods.documentConfirmedPatch
	},
	'POST_/requisitepay/v1/order': {
		service: Services.creditpay,
		method: CreditpayMethods.orderPost
	},
	'PATCH_/requisitepay/v1/order/_orderId_/executed': {
		service: Services.creditpay,
		method: CreditpayMethods.orderExecutedPatch
	},
	'POST_/current/v0/account/issue': {
		service: Services.currentAccount,
		method: CurrentAccountMethods.accountIssuePost
	},
	'PUT_/current/v0/account/issue/_issueId_': {
		service: Services.currentAccount,
		method: CurrentAccountMethods.accountIssuePut
	},
	'PATCH_/current/v0/account/issue/_issueId_/executed': {
		service: Services.currentAccount,
		method: CurrentAccountMethods.accountIssueExecutedPatch
	},
	'GET_/current/v0/openAccountInfo': {
		service: Services.currentAccount,
		method: CurrentAccountMethods.openAccountInfoGet
	},
	'GET_/customerinfo/v1/agreement': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.agreementGet
	},
	'POST_/customerinfo/v1/agreement/apply': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.agreementApplyPost
	},
	'GET_/customerinfo/v1/client/info': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.clientInfoGet
	},
	'GET_/customerinfo/v1/clients/account/_accountNumber_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.clientsAccountGet
	},
	'GET_/customerinfo/v1/clients/card/_cardNumber_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.clientsCardGet
	},
	'GET_/customerinfo/v1/consent': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.consentGet
	},
	'POST_/customerinfo/v1/consent': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.consentPost
	},
	'GET_/customerinfo/v1/consent/path': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.consentPathGet
	},
	'POST_/customerinfo/v1/contacts': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.contactsPost
	},
	'GET_/customerinfo/v1/crm/_userId_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.crmGet
	},
	'GET_/customerinfo/v1/defaultbank': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.defaultbankGet
	},
	'GET_/customerinfo/v1/defaultbank/status': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.defaultbankStatusGet
	},
	'PATCH_/customerinfo/v1/defaultbank/_awid_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.defaultbankPatch
	},
	'GET_/customerinfo/v1/ipssubscriptions/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.ipssubscriptionsSettingsGet
	},
	'PUT_/customerinfo/v1/ipssubscriptions/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.ipssubscriptionsSettingsPut
	},
	'DELETE_/customerinfo/v1/ipssubscriptions/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.ipssubscriptionsSettingsDelete
	},
	'DELETE_/customerinfo/v1/ipssubscriptions/settings/_subscriptionToken_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.ipssubscriptionsSettingsDelete
	},
	'GET_/customerinfo/v1/manager': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.managerGet
	},
	'GET_/customerinfo/v1/me2me/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsGet
	},
	'PUT_/customerinfo/v1/me2me/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsPut
	},
	'POST_/customerinfo/v1/me2me/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsPost
	},
	'DELETE_/customerinfo/v1/me2me/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsDelete
	},
	'PATCH_/customerinfo/v1/me2me/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsPatch
	},
	'DELETE_/customerinfo/v1/me2me/settings/all': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.me2meSettingsAllDelete
	},
	'PUT_/customerinfo/v1/productSettings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.productSettingsPut
	},
	'PUT_/customerinfo/v1/productSettings/mainScreen': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.productSettingsMainScreenPut
	},
	'GET_/customerinfo/v1/profile': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.profileGet
	},
	'GET_/customerinfo/v1/profile/details': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.profileDetailsGet
	},
	'GET_/customerinfo/v1/quickactions': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.quickactionsGet
	},
	'GET_/customerinfo/v1/sbpay/dependence': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpayDependenceGet
	},
	'GET_/customerinfo/v1/sbpay/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpaySettingsGet
	},
	'PUT_/customerinfo/v1/sbpay/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpaySettingsPut
	},
	'POST_/customerinfo/v1/sbpay/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpaySettingsPost
	},
	'DELETE_/customerinfo/v1/sbpay/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpaySettingsDelete
	},
	'DELETE_/customerinfo/v1/sbpay/settings/_tokenId_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.sbpaySettingsDelete
	},
	'GET_/customerinfo/v1/segment': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.segmentGet
	},
	'GET_/customerinfo/v1/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.settingsGet
	},
	'POST_/customerinfo/v1/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.settingsPost
	},
	'DELETE_/customerinfo/v1/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.settingsDelete
	},
	'PATCH_/customerinfo/v1/settings': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.settingsPatch
	},
	'POST_/customerinfo/v1/softtoken/activate': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.softtokenActivatePost
	},
	'POST_/customerinfo/v1/softtoken/deactivate': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.softtokenDeactivatePost
	},
	'POST_/customerinfo/v1/softtoken/generate': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.softtokenGeneratePost
	},
	'GET_/customerinfo/v1/softtoken/status': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.softtokenStatusGet
	},
	'PATCH_/customerinfo/v1/user/login': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.userLoginPatch
	},
	'GET_/customerinfo/v1/user/_userId_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.userGet
	},
	'GET_/customerinfo/v1/clients/card/_cardNumber_/fullResponse': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.clientsCardFullResponseGet
	},
	'GET_/customerinfo/v1/consents': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.consentsGet
	},
	'POST_/customerinfo/v1/consents': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.consentsPost
	},
	'GET_/customerinfo/v1/device': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.deviceGet
	},
	'PATCH_/customerinfo/v1/device/change/_deviceId_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.deviceChangePatch
	},
	'PATCH_/customerinfo/v1/device/deactivate': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.deviceDeactivatePatch
	},
	'PATCH_/customerinfo/v1/extended/user/_userId_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.extendedUserPatch
	},
	'GET_/customerinfo/v1/extended/user/_userId_/info': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.extendedUserInfoGet
	},
	'GET_/customerinfo/v1/legal/account/_accountNumber_': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.legalAccountGet
	},
	'GET_/customerinfo/v1/legal/info': {
		service: Services.customerinfo,
		method: CustomerinfoMethods.legalInfoGet
	},
	'GET_/deeplink/v0/long/_productType_': {
		service: Services.deeplink,
		method: DeeplinkMethods.longGet
	},
	'GET_/deeplink/v0/universal/_code_': {
		service: Services.deeplink,
		method: DeeplinkMethods.universalGet
	},
	'GET_/demandpay/v0/insurance/company/_productId_': {
		service: Services.demandpay,
		method: DemandpayMethods.insuranceCompanyGet
	},
	'POST_/demandpay/v0/insurance/requisites/_productId_': {
		service: Services.demandpay,
		method: DemandpayMethods.insuranceRequisitesPost
	},
	'POST_/demandpay/v0/legal/payment': {
		service: Services.demandpay,
		method: DemandpayMethods.legalPaymentPost
	},
	'PUT_/demandpay/v0/legal/payment/_paymentId_': {
		service: Services.demandpay,
		method: DemandpayMethods.legalPaymentPut
	},
	'PATCH_/demandpay/v0/payment/_paymentId_/executed': {
		service: Services.demandpay,
		method: DemandpayMethods.paymentExecutedPatch
	},
	'PUT_/dialog/v1/chat/_id_/messages': {
		service: Services.dialogs,
		method: DialogsMethods.chatMessagesPut
	},
	'POST_/dialog/v1/omnichat/token': {
		service: Services.dialogs,
		method: DialogsMethods.omnichatTokenPost
	},
	'POST_/dialog/v1/omnichat/action': {
		service: Services.dialogs,
		method: DialogsMethods.omnichatActionPost
	},
	'POST_/dialog/v1/chat/_id_/message': {
		service: Services.dialogs,
		method: DialogsMethods.chatMessagePost
	},
	'GET_/dialog/v1/omnichat/setting': {
		service: Services.dialogs,
		method: DialogsMethods.omnichatSettingGet
	},
	'GET_/dialog/v1/chat': {
		service: Services.dialogs,
		method: DialogsMethods.chatGet
	},
	'GET_/dialog/v1/chat/_id_/details': {
		service: Services.dialogs,
		method: DialogsMethods.chatDetailsGet
	},
	'DELETE_/dialog/v1/chat/_id_': {
		service: Services.dialogs,
		method: DialogsMethods.chatDelete
	},
	'GET_/file-upload/v1': {
		service: Services.fileUpload,
		method: FileUploadMethods.fileUploadGet
	},
	'POST_/file-upload/v1': {
		service: Services.fileUpload,
		method: FileUploadMethods.fileUploadPost
	},
	'GET_/file-upload/v1/_id_': {
		service: Services.fileUpload,
		method: FileUploadMethods.fileUploadGet
	},
	'POST_/file-upload/v1/_id_': {
		service: Services.fileUpload,
		method: FileUploadMethods.fileUploadPost
	},
	'POST_/finance/v3/carloan/link': {
		service: Services.finance,
		method: FinanceMethods.carloanLinkPost
	},
	'PATCH_/finance/v3/_accountCode_': {
		service: Services.finance,
		method: FinanceMethods.financePatch
	},
	'GET_/finance/v3/mortgage/_productId_': {
		service: Services.finance,
		method: FinanceMethods.mortgageGet
	},
	'PATCH_/finance/v3/mortgage/_productId_': {
		service: Services.finance,
		method: FinanceMethods.mortgagePatch
	},
	'GET_/finance/v3/carloan/_productId_': {
		service: Services.finance,
		method: FinanceMethods.carloanGet
	},
	'PATCH_/finance/v3/carloan/_productId_': {
		service: Services.finance,
		method: FinanceMethods.carloanPatch
	},
	'GET_/finance/v3': {
		service: Services.finance,
		method: FinanceMethods.financeGet
	},
	'GET_/finance/v3/_productId_/restructuringLink': {
		service: Services.finance,
		method: FinanceMethods.restructuringLinkGet
	},
	'GET_/finance/v3/_productId_/requisites': {
		service: Services.finance,
		method: FinanceMethods.requisitesGet
	},
	'GET_/finance/v3/_productId_/details': {
		service: Services.finance,
		method: FinanceMethods.detailsGet
	},
	'GET_/finance/v3/_productId_/actions': {
		service: Services.finance,
		method: FinanceMethods.actionsGet
	},
	'GET_/finance/v3/savings/_accountCode_': {
		service: Services.finance,
		method: FinanceMethods.savingsGet
	},
	'GET_/finance/v3/savings/_accountCode_/details': {
		service: Services.finance,
		method: FinanceMethods.savingsDetailsGet
	},
	'GET_/finance/v3/mortgage/_productId_/link': {
		service: Services.finance,
		method: FinanceMethods.mortgageLinkGet
	},
	'GET_/finance/v3/invest/_productId_/redemptionSums': {
		service: Services.finance,
		method: FinanceMethods.investRedemptionSumsGet
	},
	'GET_/finance/v3/invest/_productId_/paymentSchedule': {
		service: Services.finance,
		method: FinanceMethods.investPaymentScheduleGet
	},
	'GET_/finance/v3/invest/_productId_/details': {
		service: Services.finance,
		method: FinanceMethods.investDetailsGet
	},
	'GET_/finance/v3/insurance/_productId_': {
		service: Services.finance,
		method: FinanceMethods.insuranceGet
	},
	'GET_/finance/v3/insurance/hint': {
		service: Services.finance,
		method: FinanceMethods.insuranceHintGet
	},
	'GET_/finance/v3/deposit/_accountCode_': {
		service: Services.finance,
		method: FinanceMethods.depositGet
	},
	'GET_/finance/v3/current/_accountCode_': {
		service: Services.finance,
		method: FinanceMethods.currentGet
	},
	'GET_/finance/v3/current/_accountCode_/details': {
		service: Services.finance,
		method: FinanceMethods.currentDetailsGet
	},
	'GET_/finance/v3/credit/_productId_': {
		service: Services.finance,
		method: FinanceMethods.creditGet
	},
	'GET_/finance/v3/credit/_productId_/schedule': {
		service: Services.finance,
		method: FinanceMethods.creditScheduleGet
	},
	'GET_/finance/v3/carloan/_productId_/link': {
		service: Services.finance,
		method: FinanceMethods.carloanLinkGet
	},
	'GET_/finance/v3/actions': {
		service: Services.finance,
		method: FinanceMethods.actionsGet
	},
	'GET_/finance/v3/account': {
		service: Services.finance,
		method: FinanceMethods.accountGet
	},
	'GET_/instantpay/v0/person/phone/_paymentId_': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhoneGet
	},
	'PUT_/instantpay/v0/person/phone/_paymentId_': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhonePut
	},
	'PATCH_/instantpay/v0/person/phone/_paymentId_': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhonePatch
	},
	'PUT_/instantpay/v0/input/document/_documentId_': {
		service: Services.instantpay,
		method: InstantpayMethods.inputDocumentPut
	},
	'POST_/instantpay/v0/person/phone': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhonePost
	},
	'POST_/instantpay/v0/person/phone/technical': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhoneTechnicalPost
	},
	'POST_/instantpay/v0/person/phone/byTemplate/_templateId_': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhoneByTemplatePost
	},
	'POST_/instantpay/v0/output/check': {
		service: Services.instantpay,
		method: InstantpayMethods.outputCheckPost
	},
	'POST_/instantpay/v0/input/document': {
		service: Services.instantpay,
		method: InstantpayMethods.inputDocumentPost
	},
	'POST_/instantpay/v0/directLink': {
		service: Services.instantpay,
		method: InstantpayMethods.directLinkPost
	},
	'PATCH_/instantpay/v0/person/phone/_paymentId_/settled': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhoneSettledPatch
	},
	'PATCH_/instantpay/v0/person/phone/_paymentId_/executed': {
		service: Services.instantpay,
		method: InstantpayMethods.personPhoneExecutedPatch
	},
	'PATCH_/instantpay/v0/output/document/_documentId_/update': {
		service: Services.instantpay,
		method: InstantpayMethods.outputDocumentUpdatePatch
	},
	'PATCH_/instantpay/v0/output/document/_documentId_/execute': {
		service: Services.instantpay,
		method: InstantpayMethods.outputDocumentExecutePatch
	},
	'PATCH_/instantpay/v0/output/document/cancel': {
		service: Services.instantpay,
		method: InstantpayMethods.outputDocumentCancelPatch
	},
	'PATCH_/instantpay/v0/input/document/_documentId_/confirmPay': {
		service: Services.instantpay,
		method: InstantpayMethods.inputDocumentConfirmPayPatch
	},
	'GET_/instantpay/v0/phoneinfo/payee/_phoneNumber_': {
		service: Services.instantpay,
		method: InstantpayMethods.phoneinfoPayeeGet
	},
	'GET_/instantpay/v0/output/document': {
		service: Services.instantpay,
		method: InstantpayMethods.outputDocumentGet
	},
	'GET_/instantpay/v0/output/document/_documentId_': {
		service: Services.instantpay,
		method: InstantpayMethods.outputDocumentGet
	},
	'GET_/instantpay/v0/member/me2me': {
		service: Services.instantpay,
		method: InstantpayMethods.memberMe2meGet
	},
	'GET_/instantpay/v0/freeLimit': {
		service: Services.instantpay,
		method: InstantpayMethods.freeLimitGet
	},
	'GET_/instantpay/v0/banklist': {
		service: Services.instantpay,
		method: InstantpayMethods.banklistGet
	},
	'GET_/instantpay/v0/countryCode': {
		service: Services.instantpay,
		method: InstantpayMethods.countryCodeGet
	},
	'PATCH_/legalpay/v0/payment/_paymentId_/executed': {
		service: Services.legalpay,
		method: LegalpayMethods.paymentExecutedPatch
	},
	'PUT_/legalpay/v0/payment/_paymentId_/feecalculate': {
		service: Services.legalpay,
		method: LegalpayMethods.paymentFeecalculatePut
	},
	'PATCH_/legalpay/v0/payment/_paymentId_/settled': {
		service: Services.legalpay,
		method: LegalpayMethods.paymentSettledPatch
	},
	'POST_/legalpay/v0/sbp-c2b-aw': {
		service: Services.legalpay,
		method: LegalpayMethods.sbpC2bAwPost
	},
	'PATCH_/payment/v0/acknowledgement/_paymentId_': {
		service: Services.legalpay,
		method: LegalpayMethods.acknowledgementPatch
	},
	'POST_/payment/v1/petition/_qrcId_': {
		service: Services.legalpay,
		method: LegalpayMethods.petitionPost
	},
	'GET_/limit/v0/customer': {
		service: Services.limit,
		method: LimitMethods.customerGet
	},
	'GET_/limit/v0/customer/singleLimit': {
		service: Services.limit,
		method: LimitMethods.customerSingleLimitGet
	},
	'POST_/localization/_channel_': {
		service: Services.localization,
		method: LocalizationMethods.localization_channel_Post
	},
	'GET_/localization/_channel_/key/_key_': {
		service: Services.localization,
		method: LocalizationMethods.localization_channel_Key_key_Get
	},
	'GET_/localization/version/_version_': {
		service: Services.localization,
		method: LocalizationMethods.localizationVersion_version_Get
	},
	'GET_/loyalty/v4': {
		service: Services.loyalty,
		method: LoyaltyMethods.loyaltyGet
	},
	'GET_/loyalty/v4/banners': {
		service: Services.loyalty,
		method: LoyaltyMethods.bannersGet
	},
	'GET_/loyalty/v4/bonushistory': {
		service: Services.loyalty,
		method: LoyaltyMethods.bonushistoryGet
	},
	'GET_/loyalty/v4/bonushistory/page/_pageNumber_': {
		service: Services.loyalty,
		method: LoyaltyMethods.bonushistoryPageGet
	},
	'GET_/loyalty/v4/bonushistory/_uid_': {
		service: Services.loyalty,
		method: LoyaltyMethods.bonushistoryGet
	},
	'GET_/loyalty/v4/card': {
		service: Services.loyalty,
		method: LoyaltyMethods.cardGet
	},
	'GET_/loyalty/v4/card/category': {
		service: Services.loyalty,
		method: LoyaltyMethods.cardCategoryGet
	},
	'POST_/loyalty/v4/card/_cardId_': {
		service: Services.loyalty,
		method: LoyaltyMethods.cardPost
	},
	'GET_/loyalty/v4/card/_cardId_/category': {
		service: Services.loyalty,
		method: LoyaltyMethods.cardCategoryGet
	},
	'GET_/loyalty/v4/categories': {
		service: Services.loyalty,
		method: LoyaltyMethods.categoriesGet
	},
	'GET_/loyalty/v4/category/_categoryCode_/offers': {
		service: Services.loyalty,
		method: LoyaltyMethods.categoryOffersGet
	},
	'GET_/loyalty/v4/member/program': {
		service: Services.loyalty,
		method: LoyaltyMethods.memberProgramGet
	},
	'GET_/loyalty/v4/member/program/balanceInfo': {
		service: Services.loyalty,
		method: LoyaltyMethods.memberProgramBalanceInfoGet
	},
	'GET_/loyalty/v4/member/program/_programId_': {
		service: Services.loyalty,
		method: LoyaltyMethods.memberProgramGet
	},
	'GET_/loyalty/v4/offers': {
		service: Services.loyalty,
		method: LoyaltyMethods.offersGet
	},
	'GET_/loyalty/v4/offers/favourite': {
		service: Services.loyalty,
		method: LoyaltyMethods.offersFavouriteGet
	},
	'GET_/loyalty/v4/offers/_offerId_/details': {
		service: Services.loyalty,
		method: LoyaltyMethods.offersDetailsGet
	},
	'PUT_/loyalty/v4/offers/_offerId_/favourite': {
		service: Services.loyalty,
		method: LoyaltyMethods.offersFavouritePut
	},
	'POST_/loyalty/v4/offers/_offerId_/favourite': {
		service: Services.loyalty,
		method: LoyaltyMethods.offersFavouritePost
	},
	'GET_/loyalty/v4/operations': {
		service: Services.loyalty,
		method: LoyaltyMethods.operationsGet
	},
	'GET_/loyalty/v4/travelurl': {
		service: Services.loyalty,
		method: LoyaltyMethods.travelurlGet
	},
	'GET_/loyalty/v4/_loyaltyId_/action': {
		service: Services.loyalty,
		method: LoyaltyMethods.actionGet
	},
	'GET_/loyalty/v4/_loyaltyId_/category': {
		service: Services.loyalty,
		method: LoyaltyMethods.categoryGet
	},
	'PATCH_/loyalty/v4/_loyaltyId_/category': {
		service: Services.loyalty,
		method: LoyaltyMethods.categoryPatch
	},
	'PATCH_/loyalty/v4/_loyaltyId_/deactivate': {
		service: Services.loyalty,
		method: LoyaltyMethods.deactivatePatch
	},
	'GET_/loyalty/v4/_loyaltyId_/graph': {
		service: Services.loyalty,
		method: LoyaltyMethods.graphGet
	},
	'GET_/loyalty/v4/_loyaltyId_/rule': {
		service: Services.loyalty,
		method: LoyaltyMethods.ruleGet
	},
	'PATCH_/loyalty/v4/_loyaltyId_/switch': {
		service: Services.loyalty,
		method: LoyaltyMethods.switchPatch
	},
	'GET_/loyalty/v4/program/_program_/FAQ': {
		service: Services.loyalty,
		method: LoyaltyMethods.programFAQGet
	},
	'POST_/mortgage/v0/cardOrder': {
		service: Services.mortgage,
		method: MortgageMethods.cardOrderPost
	},
	'GET_/mortgage/v0/_productId_': {
		service: Services.mortgage,
		method: MortgageMethods.mortgageGet
	},
	'PATCH_/mortgage/v0/_productId_': {
		service: Services.mortgage,
		method: MortgageMethods.mortgagePatch
	},
	'GET_/mortgage/v0/_productId_/link': {
		service: Services.mortgage,
		method: MortgageMethods.linkGet
	},
	'GET_/mortgage/v0/_domAccountId_/account/requisites': {
		service: Services.mortgage,
		method: MortgageMethods.accountRequisitesGet
	},
	'GET_/mortgage/v0/payments': {
		service: Services.mortgage,
		method: MortgageMethods.paymentsGet
	},
	'GET_/notification/v2': {
		service: Services.notification,
		method: NotificationMethods.notificationGet
	},
	'PUT_/notification/v2/deleted': {
		service: Services.notification,
		method: NotificationMethods.deletedPut
	},
	'GET_/notification/v2/document/list': {
		service: Services.notification,
		method: NotificationMethods.documentListGet
	},
	'PUT_/notification/v2/informed': {
		service: Services.notification,
		method: NotificationMethods.informedPut
	},
	'PUT_/notification/v2/message/deleted': {
		service: Services.notification,
		method: NotificationMethods.messageDeletedPut
	},
	'GET_/notification/v2/message/history': {
		service: Services.notification,
		method: NotificationMethods.messageHistoryGet
	},
	'PATCH_/notification/v2/message/history/read': {
		service: Services.notification,
		method: NotificationMethods.messageHistoryReadPatch
	},
	'PUT_/notification/v2/read': {
		service: Services.notification,
		method: NotificationMethods.readPut
	},
	'GET_/notification/v2/splash/all': {
		service: Services.notification,
		method: NotificationMethods.splashAllGet
	},
	'GET_/notification/v2/splash/style': {
		service: Services.notification,
		method: NotificationMethods.splashStyleGet
	},
	'PATCH_/notification/v2/splash/_splashId_/backwarded': {
		service: Services.notification,
		method: NotificationMethods.splashBackwardedPatch
	},
	'PATCH_/notification/v2/splash/_splashId_/closed': {
		service: Services.notification,
		method: NotificationMethods.splashClosedPatch
	},
	'PATCH_/notification/v2/splash/_splashId_/informed': {
		service: Services.notification,
		method: NotificationMethods.splashInformedPatch
	},
	'PATCH_/notification/v2/splash/_splashId_/read': {
		service: Services.notification,
		method: NotificationMethods.splashReadPatch
	},
	'GET_/notification/v2/technical': {
		service: Services.notification,
		method: NotificationMethods.technicalGet
	},
	'PUT_/notification/v2/technical/_id_/count': {
		service: Services.notification,
		method: NotificationMethods.technicalCountPut
	},
	'GET_/template/v1/_templateId_': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templateGet
	},
	'PUT_/template/v1/_templateId_': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templatePut
	},
	'DELETE_/template/v1/_templateId_': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templateDelete
	},
	'PATCH_/template/v1/_templateId_': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templatePatch
	},
	'GET_/template/v1': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templateGet
	},
	'POST_/template/v1': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.templatePost
	},
	'POST_/template/v1/statement': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.statementPost
	},
	'POST_/template/v1/statement/_statementId_/cancelled': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.statementCancelledPost
	},
	'PATCH_/template/v1/regularpaymentorder/_scheduleId_/suspend': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.regularpaymentorderSuspendPatch
	},
	'GET_/template/v1/_templateId_/vendor': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.vendorGet
	},
	'GET_/template/v1/_templateId_/vendor/details': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.vendorDetailsGet
	},
	'GET_/template/v1/logo': {
		service: Services.operationTemplate,
		method: OperationTemplateMethods.logoGet
	},
	'POST_/paperless-office/v2/document': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.documentPost
	},
	'GET_/paperless-office/v2/document/_taskId_': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.documentGet
	},
	'POST_/paperless-office/v2/issue': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.issuePost
	},
	'PATCH_/paperless-office/v2/issue/_issueId_/execute': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.issueExecutePatch
	},
	'GET_/paperless-office/v2/relatedProducts/_taskId_': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.relatedProductsGet
	},
	'PATCH_/paperless-office/v2/relatedProducts/_taskId_/confirm': {
		service: Services.paperlessOffice,
		method: PaperlessOfficeMethods.relatedProductsConfirmPatch
	},
	'GET_/productcatalog/v0/contents': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.contentsGet
	},
	'GET_/productcatalog/v0/digitalCardOrderAvailable': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.digitalCardOrderAvailableGet
	},
	'GET_/productcatalog/v0/feed': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.feedGet
	},
	'GET_/productcatalog/v0/proposition/content': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.propositionContentGet
	},
	'GET_/productcatalog/v0/proposition/style': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.propositionStyleGet
	},
	'GET_/productcatalog/v0/style': {
		service: Services.productCatalog,
		method: ProductCatalogMethods.styleGet
	},
	'POST_/push/v0/device': {
		service: Services.push,
		method: PushMethods.devicePost
	},
	'POST_/push/v0/message/status': {
		service: Services.push,
		method: PushMethods.messageStatusPost
	},
	'PATCH_/push/v0/mfm/device': {
		service: Services.push,
		method: PushMethods.mfmDevicePatch
	},
	'GET_/push/v0/mfm/device/_deviceId_': {
		service: Services.push,
		method: PushMethods.mfmDeviceGet
	},
	'POST_/qrrecognition/v0/recognition': {
		service: Services.qrrecognition,
		method: QrrecognitionMethods.recognitionPost
	},
	'POST_/registration/v0': {
		service: Services.registration,
		method: RegistrationMethods.registrationPost
	},
	'POST_/registration/v0/webby/confirm': {
		service: Services.registration,
		method: RegistrationMethods.webbyConfirmPost
	},
	'POST_/registration/v0/webby/offer': {
		service: Services.registration,
		method: RegistrationMethods.webbyOfferPost
	},
	'POST_/registration/v0/webby/offer/agreement': {
		service: Services.registration,
		method: RegistrationMethods.webbyOfferAgreementPost
	},
	'GET_/registration/v0/webby/offer/_offerId_': {
		service: Services.registration,
		method: RegistrationMethods.webbyOfferGet
	},
	'GET_/repayment/v1/_repaymentId_': {
		service: Services.repayment,
		method: RepaymentMethods.repaymentGet
	},
	'PUT_/repayment/v1/_repaymentId_': {
		service: Services.repayment,
		method: RepaymentMethods.repaymentPut
	},
	'POST_/repayment/v1': {
		service: Services.repayment,
		method: RepaymentMethods.repaymentPost
	},
	'PATCH_/repayment/v1/_repaymentId_/executed': {
		service: Services.repayment,
		method: RepaymentMethods.executedPatch
	},
	'GET_/requisitepay/v0/private/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentGet
	},
	'PUT_/requisitepay/v0/private/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentPut
	},
	'PATCH_/requisitepay/v0/private/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentPatch
	},
	'POST_/requisitepay/v0/private/payment': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentPost
	},
	'GET_/requisitepay/v0/legal/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentGet
	},
	'PUT_/requisitepay/v0/legal/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentPut
	},
	'PATCH_/requisitepay/v0/legal/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentPatch
	},
	'POST_/requisitepay/v0/legal/payment': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentPost
	},
	'POST_/requisitepay/v0/budget/payment': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentPost
	},
	'GET_/requisitepay/v0/budget/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentGet
	},
	'PUT_/requisitepay/v0/budget/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentPut
	},
	'PATCH_/requisitepay/v0/budget/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentPatch
	},
	'PATCH_/requisitepay/v0/payment/_paymentId_/executed': {
		service: Services.requisitePay,
		method: RequisitePayMethods.paymentExecutedPatch
	},
	'GET_/requisitepay/v0/dictionary/bic/universal': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryBicUniversalGet
	},
	'GET_/requisitepay/v0/client/inn': {
		service: Services.requisitePay,
		method: RequisitePayMethods.clientInnGet
	},
	'GET_/requisitepay/v0/dictionary/budget': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryBudgetGet
	},
	'GET_/requisitepay/v0/client/details': {
		service: Services.requisitePay,
		method: RequisitePayMethods.clientDetailsGet
	},
	'GET_/requisitepay/v0/dictionary/payee_type': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryPayee_typeGet
	},
	'POST_/requisitepay/v0/budget/payment/byTemplate/_templateId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentByTemplatePost
	},
	'POST_/requisitepay/v0/legal/payment/byTemplate/_templateId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentByTemplatePost
	},
	'POST_/requisitepay/v0/private/payment/byTemplate/_templateId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentByTemplatePost
	},
	'PUT_/requisitepay/v0/contract/payment/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.contractPaymentPut
	},
	'POST_/requisitepay/v0/contract/payment': {
		service: Services.requisitePay,
		method: RequisitePayMethods.contractPaymentPost
	},
	'POST_/requisitepay/v0/contract/payment/byTemplate/_templateId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.contractPaymentByTemplatePost
	},
	'PATCH_/requisitepay/v0/private/payment/partialUpdate/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.privatePaymentPartialUpdatePatch
	},
	'PATCH_/requisitepay/v0/legal/payment/partialUpdate/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.legalPaymentPartialUpdatePatch
	},
	'PATCH_/requisitepay/v0/contract/payment/_paymentId_/executed': {
		service: Services.requisitePay,
		method: RequisitePayMethods.contractPaymentExecutedPatch
	},
	'PATCH_/requisitepay/v0/budget/payment/partialUpdate/_paymentId_': {
		service: Services.requisitePay,
		method: RequisitePayMethods.budgetPaymentPartialUpdatePatch
	},
	'GET_/requisitepay/v0/dictionary/company': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryCompanyGet
	},
	'GET_/requisitepay/v0/dictionary/bic': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryBicGet
	},
	'GET_/requisitepay/v0/dictionary/bicRuled': {
		service: Services.requisitePay,
		method: RequisitePayMethods.dictionaryBicRuledGet
	},
	'GET_/sale/v0/auth/forward/_bannerId_': {
		service: Services.sale,
		method: SaleMethods.authForwardGet
	},
	'POST_/sale/v0/auth/forward/_bannerId_': {
		service: Services.sale,
		method: SaleMethods.authForwardPost
	},
	'GET_/sale/v0/auth/forward/_bannerId_/_productCode_': {
		service: Services.sale,
		method: SaleMethods.authForwardGet
	},
	'GET_/sale/v0/auth/forward/_bannerId_/_productCode_/_appId_': {
		service: Services.sale,
		method: SaleMethods.authForwardGet
	},
	'GET_/sale/v0/auth/p2u': {
		service: Services.sale,
		method: SaleMethods.authP2uGet
	},
	'GET_/sale/v0/card/update': {
		service: Services.sale,
		method: SaleMethods.cardUpdateGet
	},
	'GET_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStyleGet
	},
	'HEAD_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStyleHead
	},
	'POST_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStylePost
	},
	'PUT_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStylePut
	},
	'DELETE_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStyleDelete
	},
	'OPTIONS_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStyleOptions
	},
	'PATCH_/sale/v0/static/style': {
		service: Services.sale,
		method: SaleMethods.staticStylePatch
	},
	'POST_/saving/v0/account/issue': {
		service: Services.saving,
		method: SavingMethods.accountIssuePost
	},
	'PUT_/saving/v0/account/issue/_issueId_': {
		service: Services.saving,
		method: SavingMethods.accountIssuePut
	},
	'PATCH_/saving/v0/account/issue/_issueId_/executed': {
		service: Services.saving,
		method: SavingMethods.accountIssueExecutedPatch
	},
	'GET_/saving/v0/account/_accountCode_/banner': {
		service: Services.saving,
		method: SavingMethods.accountBannerGet
	},
	'GET_/saving/v0/bonus/accounts': {
		service: Services.saving,
		method: SavingMethods.bonusAccountsGet
	},
	'GET_/saving/v0/bonus/condition': {
		service: Services.saving,
		method: SavingMethods.bonusConditionGet
	},
	'GET_/saving/v0/bonus/description': {
		service: Services.saving,
		method: SavingMethods.bonusDescriptionGet
	},
	'GET_/saving/v0/bonus/mcc': {
		service: Services.saving,
		method: SavingMethods.bonusMccGet
	},
	'GET_/saving/v0/bonus/_accountCode_/details': {
		service: Services.saving,
		method: SavingMethods.bonusDetailsGet
	},
	'GET_/saving/v0/bonus/_accountCode_/history': {
		service: Services.saving,
		method: SavingMethods.bonusHistoryGet
	},
	'POST_/saving/v0/bonus/_accountCode_/order': {
		service: Services.saving,
		method: SavingMethods.bonusOrderPost
	},
	'GET_/saving/v0/deposit/calculation': {
		service: Services.saving,
		method: SavingMethods.depositCalculationGet
	},
	'PATCH_/saving/v0/deposit/closeDepositIssue/_id_/executed': {
		service: Services.saving,
		method: SavingMethods.depositCloseDepositIssueExecutedPatch
	},
	'PUT_/saving/v0/deposit/document/_id_': {
		service: Services.saving,
		method: SavingMethods.depositDocumentPut
	},
	'GET_/saving/v0/deposit/document/_id_/conditions': {
		service: Services.saving,
		method: SavingMethods.depositDocumentConditionsGet
	},
	'POST_/saving/v0/deposit/issue': {
		service: Services.saving,
		method: SavingMethods.depositIssuePost
	},
	'PUT_/saving/v0/deposit/issue/_id_': {
		service: Services.saving,
		method: SavingMethods.depositIssuePut
	},
	'PATCH_/saving/v0/deposit/issue/_id_/executed': {
		service: Services.saving,
		method: SavingMethods.depositIssueExecutedPatch
	},
	'GET_/saving/v0/deposit/issue/_id_/form': {
		service: Services.saving,
		method: SavingMethods.depositIssueFormGet
	},
	'GET_/saving/v0/deposit/issue/_id_/precondition': {
		service: Services.saving,
		method: SavingMethods.depositIssuePreconditionGet
	},
	'GET_/saving/v0/deposit/issue/_id_/search': {
		service: Services.saving,
		method: SavingMethods.depositIssueSearchGet
	},
	'GET_/saving/v0/deposit/issue/_id_/threshold': {
		service: Services.saving,
		method: SavingMethods.depositIssueThresholdGet
	},
	'POST_/saving/v0/deposit/order/_id_': {
		service: Services.saving,
		method: SavingMethods.depositOrderPost
	},
	'GET_/saving/v0/deposit/profit/_id_': {
		service: Services.saving,
		method: SavingMethods.depositProfitGet
	},
	'PATCH_/saving/v0/deposit/withdrawIssue/_id_/executed': {
		service: Services.saving,
		method: SavingMethods.depositWithdrawIssueExecutedPatch
	},
	'POST_/saving/v0/deposit/_accountCode_/closeDepositIssue': {
		service: Services.saving,
		method: SavingMethods.depositCloseDepositIssuePost
	},
	'POST_/saving/v0/deposit/_accountCode_/withdrawIssue': {
		service: Services.saving,
		method: SavingMethods.depositWithdrawIssuePost
	},
	'GET_/saving/v0/deposit/_id_/conditions/pdf/preparation': {
		service: Services.saving,
		method: SavingMethods.depositConditionsPdfPreparationGet
	},
	'GET_/saving/v0/deposit/_orderId_/conditions/pdf': {
		service: Services.saving,
		method: SavingMethods.depositConditionsPdfGet
	},
	'GET_/saving/v0/openAccountInfo': {
		service: Services.saving,
		method: SavingMethods.openAccountInfoGet
	},
	'POST_/servicepay/v3/direct/invoice': {
		service: Services.servicePay,
		method: ServicePayMethods.directInvoicePost
	},
	'PUT_/servicepay/v3/direct/invoice/_invoiceId_/step/_stepName_': {
		service: Services.servicePay,
		method: ServicePayMethods.directInvoicePut
	},
	'POST_/servicepay/v3/direct/payment': {
		service: Services.servicePay,
		method: ServicePayMethods.directPaymentPost
	},
	'GET_/servicepay/v3/direct/payment/_paymentId_': {
		service: Services.servicePay,
		method: ServicePayMethods.directPaymentGet
	},
	'PUT_/servicepay/v3/direct/payment/_paymentId_': {
		service: Services.servicePay,
		method: ServicePayMethods.directPaymentPut
	},
	'PATCH_/servicepay/v3/direct/payment/_paymentId_/executed': {
		service: Services.servicePay,
		method: ServicePayMethods.directPaymentExecutedPatch
	},
	'POST_/servicepay/v3/invoice': {
		service: Services.servicePay,
		method: ServicePayMethods.invoicePost
	},
	'PUT_/servicepay/v3/invoice/_invoiceId_/step/_stepName_': {
		service: Services.servicePay,
		method: ServicePayMethods.invoicePut
	},
	'POST_/servicepay/v3/payment': {
		service: Services.servicePay,
		method: ServicePayMethods.paymentPost
	},
	'GET_/servicepay/v3/payment/_paymentId_': {
		service: Services.servicePay,
		method: ServicePayMethods.paymentGet
	},
	'PUT_/servicepay/v3/payment/_paymentId_': {
		service: Services.servicePay,
		method: ServicePayMethods.paymentPut
	},
	'PATCH_/servicepay/v3/payment/_paymentId_/executed': {
		service: Services.servicePay,
		method: ServicePayMethods.paymentExecutedPatch
	},
	'GET_/servicepay/v3/payment/_paymentId_/repeat': {
		service: Services.servicePay,
		method: ServicePayMethods.paymentRepeatGet
	},
	'POST_/servicepoint/v0/list': {
		service: Services.servicePoint,
		method: ServicePointMethods.listPost
	},
	'POST_/servicepoint/v0/map': {
		service: Services.servicePoint,
		method: ServicePointMethods.mapPost
	},
	'GET_/servicepoint/v0/office/_officeCode_/details': {
		service: Services.servicePoint,
		method: ServicePointMethods.officeDetailsGet
	},
	'GET_/servicepoint/v0/officeTimeTable/_officeId_': {
		service: Services.servicePoint,
		method: ServicePointMethods.officeTimeTableGet
	},
	'POST_/statement/v0/personal': {
		service: Services.statement,
		method: StatementMethods.personalPost
	},
	'POST_/statement/v0/accountStatement': {
		service: Services.statement,
		method: StatementMethods.accountStatementPost
	},
	'POST_/statement/v0/accountStatement/file': {
		service: Services.statement,
		method: StatementMethods.accountStatementFilePost
	},
	'GET_/statement/v0': {
		service: Services.statement,
		method: StatementMethods.statementGet
	},
	'GET_/statement/v0/personalAvailableStatements': {
		service: Services.statement,
		method: StatementMethods.personalAvailableStatementsGet
	},
	'GET_/statement/v0/cardStatement/_operationUid_': {
		service: Services.statement,
		method: StatementMethods.cardStatementGet
	},
	'GET_/statement/v0/accountStatement/_requestId_': {
		service: Services.statement,
		method: StatementMethods.accountStatementGet
	},
	'GET_/statement/v0/accountStatement/file/_statementDocumentId_': {
		service: Services.statement,
		method: StatementMethods.accountStatementFileGet
	},
	'GET_/tariff-api/v0/priority': {
		service: Services.tariff,
		method: TariffMethods.priorityGet
	},
	'GET_/tariff-api/v0/priority/passes': {
		service: Services.tariff,
		method: TariffMethods.priorityPassesGet
	},
	'GET_/tariff-api/v0/tariff': {
		service: Services.tariff,
		method: TariffMethods.tariffGet
	},
	'GET_/tariff-api/v0/tariff/receipt/_code_': {
		service: Services.tariff,
		method: TariffMethods.tariffReceiptGet
	},
	'GET_/vendor/v0/category': {
		service: Services.vendor,
		method: VendorMethods.categoryGet
	},
	'GET_/vendor/v0/category/_categoryId_': {
		service: Services.vendor,
		method: VendorMethods.categoryGet
	},
	'GET_/vendor/v0/category/_categoryId_/product': {
		service: Services.vendor,
		method: VendorMethods.categoryProductGet
	},
	'GET_/vendor/v0/product': {
		service: Services.vendor,
		method: VendorMethods.productGet
	},
	'GET_/vendor/v0/product/_productId_': {
		service: Services.vendor,
		method: VendorMethods.productGet
	},
	'GET_/vendor/v0/product/_productId_/settings': {
		service: Services.vendor,
		method: VendorMethods.productSettingsGet
	},
};